import React from "react";

export default function Marketplace() {
  return (
    <div>
      <div class="grid grid-cols-5 pt-16 px-10 gap-8">
        <div class="w-full h-52 bg-grey-light rounded-lg"></div>
        <div class="w-full h-52 bg-grey-light rounded-lg"></div>
        <div class="w-full h-52 bg-grey-light rounded-lg"></div>
        <div class="w-full h-52 bg-grey-light rounded-lg"></div>
        <div class="w-full h-52 bg-grey-light rounded-lg"></div>
      </div>
      <div class="grid grid-cols-5 pt-16 px-10 gap-8">
        <div class="w-full h-96 col-span-4 bg-grey-light rounded-lg"></div>
        <div class="w-full h-96 bg-grey-light rounded-lg"></div>
      </div>
    </div>
  );
}
