import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Button from "./Button";
import { startCheck } from "../utils/Requests";
import CheckCreator from "./CheckCreator";
import CheckProgress from "./CheckProgress";

export default function Check() {
  const { folderId } = useParams();
  const [checkId, setCheckId] = useState(null);
  const [checkTitle, setCheckTitle] = useState("");

  return (
    <div>
      <div class="grid grid-cols-4 h-full">
        <div class="col-span-3 bg-grey-light">
          {checkId ? (
            <CheckProgress checkId={checkId} />
          ) : (
            <CheckCreator folderId={folderId} />
          )}
        </div>
        <div class="bg-blue-pale p-4">
          Check Title
          <input
            type="text"
            value={checkTitle}
            onChange={(e) => setCheckTitle(e.target.value)}
          ></input>
          <Button
            onClick={async () => {
              const response = await startCheck(
                checkTitle.length > 0
                  ? checkTitle
                  : "Check - " + new Date().toLocaleString(),
                folderId,
                [],
                {
                  internalComparison: true,
                }
              );
              setCheckId(response);
              window.location.href = "/checks/" + response;
            }}
          >
            Start Check
          </Button>
        </div>
      </div>
    </div>
  );
}
