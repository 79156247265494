import axios from "axios";
import getAuthHeaders from "./Helpers";

const nukeDatabase = async () => {
  const config = await getAuthHeaders();
  const result = await axios.get(
    process.env.REACT_APP_SERVER_URL + "/admin/nuke-database",
    config
  );
  return result.data;
};

const deleteDatabaseCollection = async (collection) => {
  const config = await getAuthHeaders();
  const result = await axios.post(
    process.env.REACT_APP_SERVER_URL + "/admin/delete-database-collection",
    { collection },
    config
  );
  return result.data;
};

const getAllUsers = async () => {
  const config = await getAuthHeaders();
  const result = await axios.get(
    process.env.REACT_APP_SERVER_URL + "/admin/get-users",
    config
  );
  return result.data;
};

const getAllCollections = async () => {
  const config = await getAuthHeaders();
  const result = await axios.get(
    process.env.REACT_APP_SERVER_URL + "/admin/get-collections",
    config
  );
  return result.data;
};

const deleteEchoCollection = async (collectionId) => {
  console.log("🚀 ~ deleteEchoCollection ~ collectionId:", collectionId);
  const config = await getAuthHeaders();
  const result = await axios.post(
    process.env.REACT_APP_SERVER_URL + "/delete-collection",
    { collectionId },
    config
  );
  return result.data;
};

export {
  nukeDatabase,
  deleteDatabaseCollection,
  getAllUsers,
  getAllCollections,
  deleteEchoCollection,
};
