import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { faFolder, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "./Button";

const FileUpload = ({ setFiles, files }) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setFiles([...files, ...acceptedFiles]);
    },
  });
  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <div class="w-full my-8 mx-16 border-4 border-grey-light border-dashed rounded-lg p-8 text-black cursor-pointer">
        <div class="flex justify-center">
          <img src="/files.png" class="h-24"></img>
        </div>
        <p class="text-center text-lg mt-4">
          Drop your files here or{" "}
          <span class="font-bold text-blue-dark underline cursor-pointer">
            browse
          </span>
        </p>
        <p class="text-center text-md text-grey-dark">
          Supports .pdf, .txt, .doc, .docx, .xls, .xlxs
        </p>
      </div>
    </div>
  );
};
export default FileUpload;
