import React, { useEffect, useState } from "react";
import { getCheckProgress } from "../utils/Requests";
import Button from "./Button";

export default function CheckProgress({ checkId }) {
  const [progress, setProgress] = useState(null);

  //   useEffect(() => {
  //     const getProgress = async () => {
  //       const result = await getCheckProgress(checkId);
  //       setProgress(result);
  //       setTimeout(getProgress, 5000);
  //     };
  //     getProgress();
  //   }, []);

  return (
    <div>
      CheckProgress {checkId}
      <Button
        onClick={() => {
          window.location.href = "/checks/" + checkId;
        }}
      >
        [TEMP] Go to Check Progress
      </Button>
    </div>
  );
}
