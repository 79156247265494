import React, { useEffect, useState } from "react";
import FileUpload from "../FileUpload";
import { v4 as uuidv4 } from "uuid";
import { populateFolder } from "../../utils/Requests";
import Loading from "../Loading";

export default function FilesTab({ folderId, folder }) {
  console.log("🚀 ~ FilesTab ~ folder:", folder);
  const [files, setFiles] = useState([]);
  console.log("🚀 ~ FilesTab ~ files:", files);
  const [currentUploads, setCurrentUploads] = useState({});
  console.log("🚀 ~ FilesTab ~ currentUploads:", currentUploads);

  useEffect(() => {
    const uploadFiles = async () => {
      const response = await populateFolder(folderId, files);
      console.log("🚀 ~ uploadFiles ~ response:", response);
      if (response) {
        window.location.reload();
      }
    };
    if (files.length > 0) {
      console.log("uploading", files);
      const uploadId = uuidv4();
      setCurrentUploads({ ...currentUploads, [uploadId]: files });
      uploadFiles();
      setFiles([]);
    }
  }, [files]);

  return (
    <div class="w-full pr-32">
      <FileUpload setFiles={setFiles} files={files} />
      {Object.values(currentUploads).length > 0 && (
        <>
          <Loading></Loading>
          <div class="flex items-center justify-center text-blue-dark text-3xl">
            Uploading your files, do not refresh the page...
          </div>
        </>
      )}
      {folder.items && (
        // folder.items.map((item) => <div>{JSON.stringify(item)}</div>)}
        <>{folder.items.length + " items"}</>
      )}
      {/* {JSON.stringify(files)} */}
      {/* {JSON.stringify(currentUploads)} */}
    </div>
  );
}
