import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export default function Loading({ size = "10x" }) {
  return (
    <div class="p-10 flex items-center justify-center text-blue-dark">
      <FontAwesomeIcon icon={faSpinner} size={size} spin={true} />
    </div>
  );
}
