import React, { useState } from "react";
import Modal from "../Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolderPlus } from "@fortawesome/free-solid-svg-icons";
import Button from "../Button";
import { createFolder } from "../../utils/Requests";

const InputBox = ({ title, placeholder, value, setValue, multiline }) => (
  <div class="p-1 border-2 border-black rounded-md relative mt-12">
    {multiline ? (
      <>
        <h2 class="absolute -top-7 translate-y-1/2 px-2 bg-white">{title}</h2>
        <textarea
          class="w-full h-32 border-none outline-none pl-2 resize-none pt-1"
          placeholder={placeholder}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          rows={10}
        ></textarea>
      </>
    ) : (
      <>
        <h2 class="absolute -top-1/2 translate-y-1/2 px-2 bg-white">{title}</h2>
        <input
          class="w-full h-10 border-none outline-none pl-2"
          placeholder={placeholder}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        ></input>
      </>
    )}
  </div>
);

export default function NewFolder({ banner }) {
  const [newFolderModal, setNewFolderModal] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [tags, setTags] = useState("");

  return (
    <>
      <Modal isOpen={newFolderModal} setIsOpen={setNewFolderModal} white>
        <div class="text-blue-light text-2xl">
          <span class="mr-2">
            <FontAwesomeIcon icon={faFolderPlus} />
          </span>
          New Folder
        </div>
        <InputBox
          title="Title"
          placeholder="Add title"
          value={title}
          setValue={setTitle}
        />
        <InputBox
          title="Description"
          placeholder="Add description"
          value={description}
          setValue={setDescription}
          multiline
        ></InputBox>
        <InputBox
          title="Add tags"
          placeholder="Add tag name"
          value={tags}
          setValue={setTags}
        />
        <div class="flow-root mt-8">
          <div class="flow-root float-right">
            <div class="float-left w-24 mr-4">
              <Button secondary onClick={() => setNewFolderModal(false)}>
                Cancel
              </Button>
            </div>
            <div class="float-right w-24">
              <Button
                secondary
                disabled={title.length === 0}
                onClick={async () => {
                  const response = await createFolder(title, description, tags);
                  setNewFolderModal(false);
                  window.location.reload();
                }}
              >
                Create
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <div class="w-52 mx-auto font-bold py-8">
        <Button white onClick={() => setNewFolderModal(true)}>
          {banner ? (
            <>Start here</>
          ) : (
            <>
              <FontAwesomeIcon icon={faFolderPlus} /> New Folder
            </>
          )}
        </Button>
      </div>
    </>
  );
}
