import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFolder,
  faFolderPlus,
  faGear,
  faHouse,
  faRightFromBracket,
  faShoppingBag,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Button from "../Button";
import Modal from "../Modal";
import NewFolder from "./NewFolder";
import { useAuth } from "../../contexts/AuthContext";

export default function SidebarWrapper({ children }) {
  const [selected, setSelected] = useState(window?.location?.pathname || "/");
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);
  const { logout } = useAuth();

  console.log(window.location.pathname);

  const MenuItem = ({ text, icon, route }) => {
    const isSelected = selected === route;
    return (
      <Link to={route} onClick={() => setSelected(route)}>
        <div
          class={`cursor-pointer text-lg ml-6 p-3 pl-6 ${
            isSelected
              ? "text-white bg-blue-dark rounded-l-lg"
              : "text-white hover:bg-blue-dark hover:rounded-l-lg"
          }`}
        >
          <span class="mr-2">
            <FontAwesomeIcon icon={icon} />
          </span>
          {text}
        </div>
      </Link>
    );
  };
  return (
    <div class="flex h-[calc(100vh-64px)]">
      <Modal logout isOpen={logoutModalOpen} setIsOpen={setLogoutModalOpen}>
        <div class="">
          <div class="text-blue-light flex items-center justify-center">
            <FontAwesomeIcon
              icon={faRightFromBracket}
              size="2x"
            ></FontAwesomeIcon>
          </div>
          <div class="mt-2 flex items-center justify-center text-center text-lg">
            Oh no! You're leaving...
            <br></br>
            Are you sure?
          </div>
          <div class="flow-root mt-10 mx-12">
            <div class="float-left w-24 mr-4">
              <Button secondary onClick={() => setLogoutModalOpen(false)}>
                No
              </Button>
            </div>
            <div class="float-right w-24">
              <Button secondary onClick={() => logout()}>
                Yes
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <div class="bg-blue w-72">
        <NewFolder></NewFolder>
        <MenuItem text="Content" icon={faFolder} route="/"></MenuItem>
        <MenuItem
          text="Marketplace"
          icon={faShoppingBag}
          route="/marketplace"
        ></MenuItem>
        <MenuItem text="Trash" icon={faTrash} route="/trash"></MenuItem>
        <div class="bottom-0 fixed cursor-pointer text-lg text-white w-60 py-4">
          <div class="text-blue-light">
            <hr></hr>
          </div>
          <div
            class="h-full pt-4 ml-8 flex items-center"
            onClick={() => setLogoutModalOpen(true)}
          >
            <FontAwesomeIcon icon={faRightFromBracket}></FontAwesomeIcon>
            <div class="pl-3">Log out</div>
          </div>
        </div>
        {/* <div class="h-40"></div>
        <div class="text-white">
          ---- Everything below this point will soon be deleted ----
        </div>
        <MenuItem text="Home" icon={faHouse} route="/"></MenuItem>
        <MenuItem
          text="Collections"
          icon={faFolder}
          route="/collections"
        ></MenuItem>
        <MenuItem text="Settings" icon={faGear} route="/settings"></MenuItem> */}
      </div>
      <div class="bg-white w-full">{children}</div>
    </div>
  );
}
