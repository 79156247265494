import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import {
  acceptInvite,
  dismissNotification,
  seeNotifications,
} from "../../utils/Requests";
import { Link } from "react-router-dom";
import Button from "../Button";

export default function Notifications({ notifications, setNotifications }) {
  const [newNotifications, setNewNotifications] = useState([]);

  useEffect(() => {
    setNewNotifications(
      notifications
        .filter((notification) => notification.status === "new")
        .map((notification) => notification._id)
    );
    seeNotifications(notifications.map((notification) => notification._id));
    setNotifications(
      notifications.map((notification) => ({ ...notification, status: "seen" }))
    );
  }, []);

  const onDismiss = (notificationId) => {
    console.log("🚀 ~ onDismiss ~ notificationId:", notificationId);
    const updatedNotifications = notifications.filter(
      (notification) => notification._id !== notificationId
    );
    setNotifications(updatedNotifications);
    dismissNotification(notificationId);
  };

  const handleButtonClick = async (action, notificationId) => {
    console.log("action", action);
    if (action.type === "acceptInvite") {
      const response = await acceptInvite(action.data);
      console.log("response");
      if (response !== "error") {
        onDismiss(notificationId);
      }
      return;
    }
    if (action.type === "declineInvite") {
      onDismiss(notificationId);
      return;
    }
  };

  const NotificationContent = ({ notification }) => (
    <div>
      <div class="text-xs">
        {newNotifications.includes(notification._id) && (
          <div class="font-bold inline text-red mr-2">NEW</div>
        )}
        <div class="text-grey inline">
          {new Date(notification.date).toLocaleString()}
        </div>
      </div>
      <div class="flow-root text-blue-dark">
        <p class="float-left font-bold text-xl">{notification.header}</p>
        {notification.dismissable && (
          <div
            class="float-right cursor-pointer"
            onClick={() => onDismiss(notification._id)}
          >
            <FontAwesomeIcon icon={faClose} size="2x"></FontAwesomeIcon>
          </div>
        )}
      </div>
      <p>{notification.text}</p>
      {notification.buttons &&
        notification.buttons.map(({ text, action, props }) => (
          <div
            class={`inline-flex w-1/${notification.buttons.length} px-2 pt-1`}
          >
            <Button
              onClick={() => handleButtonClick(action, notification._id)}
              {...props}
            >
              {text}
            </Button>
          </div>
        ))}
    </div>
  );

  const Notification = ({ notification }) => (
    <div
      class={`border-2 border-${
        newNotifications.includes(notification._id) ? "red" : "blue-dark"
      } p-4 m-2 rounded-xl`}
    >
      {notification.link ? (
        <Link to={notification.link}>
          <NotificationContent notification={notification} />
        </Link>
      ) : (
        <NotificationContent notification={notification} />
      )}
    </div>
  );

  return (
    <div class="w-96 overflow-auto max-h-96">
      <div class="font-bold text-right text-lg">
        {notifications.length} Notifications
      </div>
      <ul>
        {notifications.map((notification) => (
          <li key={notification._id}>
            <Notification notification={notification} />
          </li>
        ))}
      </ul>
    </div>
  );
}
