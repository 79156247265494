import { Link } from "react-router-dom";
import auth from "../../config/firebase";

import { useAuth } from "../../contexts/AuthContext";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import ProfileIcon from "../accounts/ProfileIcon";
import Notifications from "./Notifications";
import { getNotifications, seeNotifications } from "../../utils/Requests";

export default function Header() {
  const { currentUser, logout } = useAuth();
  const [profileDropdown, setProfileDropdown] = useState(false);
  const [notificationsDropdown, setNotificationsDropdown] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [browserNotificationsSent, setBrowserNotificationsSent] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = auth.currentUser;
        const token = user && (await user.getIdToken());

        const payloadHeader = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };
        const res = await fetch(
          process.env.REACT_APP_SERVER_URL + "",
          payloadHeader
        );
        console.log(await res.text());
      } catch (e) {
        console.log(e);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const checkNotifications = async () => {
      const result = await getNotifications();
      if (!currentUser) {
        setTimeout(checkNotifications, 5000);
        return;
      }
      setNotifications(result.reverse());
      setTimeout(checkNotifications, 5000);
    };
    checkNotifications();
  }, []);

  useEffect(() => {
    const newNotifications = notifications.filter((n) => n.status === "new");
    if (newNotifications.length > 0) {
      console.log("browserNotificationsSent", browserNotificationsSent);
      for (const notification of newNotifications.reverse()) {
        if (!browserNotificationsSent.includes(notification._id)) {
          console.log(notification.header);
          const browserNotification = new Notification(notification.header, {
            body: notification.text,
            icon: "/favicon.ico",
          });
          if (notification.link) {
            browserNotification.onclick = (e) => {
              e.preventDefault();
              window.open(notification?.link);
              setNotifications(
                notifications.map((notification) => ({
                  ...notification,
                  status: "seen",
                }))
              );
              seeNotifications([notification._id]);
              browserNotification.close();
            };
          }
          setBrowserNotificationsSent([
            ...browserNotificationsSent,
            notification._id,
          ]);
        }
      }
    }
  }, [notifications]);

  useEffect(() => {
    if (!("Notification" in window)) {
      console.log("Browser does not support desktop notification");
    } else {
      Notification.requestPermission();
    }
  }, []);

  return (
    <nav class="sticky top-0 bg-blue-dark p-2 pl-4 z-50">
      <div class="flex flex-wrap justify-between items-center">
        <span>
          <Link to="/">
            <img class="m-3 w-28" src="/Echo.png"></img>
          </Link>
        </span>
        <span class="items-end">
          {currentUser && (
            <div class="content flex">
              <div
                class="content flex pr-8 cursor-pointer"
                onClick={() => setNotificationsDropdown(!notificationsDropdown)}
              >
                {notifications.some((n) => n.status === "new") && (
                  <div class="absolute w-3 h-3 bg-red rounded-full"></div>
                )}
                <span class="text-2xl text-white">
                  <FontAwesomeIcon icon={faBell} />
                </span>
                <span class="text-white text-md ml-2 mt-1">Notifications</span>
              </div>
              {notificationsDropdown && (
                <ul
                  role="menu"
                  data-popover="menu"
                  data-popover-placement="bottom"
                  class="w-90 absolute right-40 top-12 z-10 mt-2 overflow-auto rounded-md border border-blue-gray-50 bg-white p-1 font-sans text-sm font-normal text-blue-gray-500 shadow-lg shadow-blue-gray-500/10 focus:outline-none"
                >
                  <li
                    role="menuitem"
                    class="block w-full select-none rounded-md px-1 pt-[9px] pb-2 text-start leading-tight transition-all hover:bg-blue-gray-50 hover:bg-opacity-80 hover:text-blue-gray-900 focus:bg-blue-gray-50 focus:bg-opacity-80 focus:text-blue-gray-900 active:bg-blue-gray-50 active:bg-opacity-80 active:text-blue-gray-900"
                  >
                    <Notifications
                      notifications={notifications}
                      setNotifications={setNotifications}
                    ></Notifications>
                  </li>
                </ul>
              )}

              <div
                class="pr-8 cursor-pointer"
                onClick={() => setProfileDropdown(!profileDropdown)}
              >
                <ProfileIcon user={currentUser}></ProfileIcon>
                {profileDropdown && (
                  <ul
                    role="menu"
                    data-popover="menu"
                    data-popover-placement="bottom"
                    class="absolute z-10 mt-2 overflow-auto rounded-md border border-blue-gray-50 bg-white p-3 font-sans text-sm font-normal text-blue-gray-500 shadow-lg shadow-blue-gray-500/10 focus:outline-none"
                  >
                    <li
                      role="menuitem"
                      class="block w-full cursor-pointer select-none rounded-md px-3 pt-[9px] pb-2 text-start leading-tight transition-all hover:bg-blue-gray-50 hover:bg-opacity-80 hover:text-blue-gray-900 focus:bg-blue-gray-50 focus:bg-opacity-80 focus:text-blue-gray-900 active:bg-blue-gray-50 active:bg-opacity-80 active:text-blue-gray-900"
                    >
                      <Link to="/profile">Edit Profile</Link>
                    </li>
                    <li
                      role="menuitem"
                      class="block w-full cursor-pointer select-none rounded-md px-3 pt-[9px] pb-2 text-start leading-tight transition-all hover:bg-blue-gray-50 hover:bg-opacity-80 hover:text-blue-gray-900 focus:bg-blue-gray-50 focus:bg-opacity-80 focus:text-blue-gray-900 active:bg-blue-gray-50 active:bg-opacity-80 active:text-blue-gray-900"
                    >
                      <button onClick={() => logout()}>Logout</button>
                    </li>
                  </ul>
                )}
              </div>
            </div>
          )}
        </span>
      </div>
    </nav>
  );
}
