import React, { useState } from "react";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Table({
  data,
  headers,
  filter = [],
  renderData,
  disableSort,
  linkKey,
  selected,
  setSelected,
  multiSelect,
}) {
  const [sort, setSort] = useState(null);
  const [sortInverted, setSortInverted] = useState(false);
  return (
    <div class="overflow-y-auto block max-h-96 mt-6">
      <table class="w-full  border border-blue-light">
        <thead class="bg-blue-light text-white font-medium sticky top-0">
          <tr>
            {setSelected && <th></th>}
            {(headers || Object.keys(data[0]))
              .filter((key) => !filter.includes(key))
              .map((key) => (
                <th
                  class="px-6 py-3"
                  onClick={() =>
                    sort === key && !disableSort
                      ? setSortInverted(!sortInverted)
                      : setSort(key)
                  }
                >
                  <span class={`${disableSort ? "" : "cursor-pointer"}`}>
                    {key.toUpperCase()}
                    <span
                      class={`ml-2 ${
                        sort === key ? "text-blue-dark" : "text-white"
                      }`}
                    >
                      {!disableSort && (
                        <span>
                          {sortInverted && sort === key ? (
                            <FontAwesomeIcon
                              icon={faChevronUp}
                            ></FontAwesomeIcon>
                          ) : (
                            <FontAwesomeIcon
                              icon={faChevronDown}
                            ></FontAwesomeIcon>
                          )}
                        </span>
                      )}
                    </span>
                  </span>
                </th>
              ))}
          </tr>
        </thead>
        <tbody class="max-h-96 overflow-y-auto">
          {data
            .sort((a, b) => {
              if (a[sort] < b[sort]) {
                return sortInverted ? 1 : -1;
              }
              if (a[sort] > b[sort]) {
                return sortInverted ? -1 : 1;
              }
              return 0;
            })
            .map((item) => (
              <tr
                key={item.id}
                onClick={() =>
                  linkKey ? (window.location.href = item[linkKey]) : {}
                }
                class={`${
                  linkKey
                    ? "cursor-pointer hover:bg-blue-highlight hover:text-white"
                    : ""
                }`}
              >
                {setSelected && (
                  <td>
                    {multiSelect ? (
                      <input
                        type="checkbox"
                        class="form-checkbox h-5 w-5"
                        checked={selected.includes(item)}
                        onChange={() => {
                          selected.includes(item)
                            ? setSelected(selected.filter((i) => i !== item))
                            : setSelected([...selected, item]);
                        }}
                      />
                    ) : (
                      <input
                        type="radio"
                        name="radio"
                        class="form-radio h-5 w-5"
                        checked={selected === item}
                        onChange={() => setSelected(item)}
                      />
                    )}
                  </td>
                )}
                {Object.keys(item)
                  .filter((key) => !filter.includes(key))
                  .map((key) => (
                    <td>
                      {renderData ? renderData(key, item[key]) : item[key]}
                    </td>
                  ))}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}
