import { useAuth } from "../../contexts/AuthContext";

export default function ErrorMessage() {
  const { error, setError } = useAuth();
  console.log("🚀 ~ ErrorMessage ~ error:", error);

  return (
    error && (
      <div>
        <div>
          <div>
            <div onClick={() => setError("")}>X</div>
            <div>
              <h3>Error: {error}</h3>
            </div>
          </div>
        </div>
      </div>
    )
  );
}
