import React, { useEffect, useState } from "react";
import Button from "../../../Button";
import { Link } from "react-router-dom";
import Status from "../../../Status";
import TabPages from "../../../TabPages";
import ProfileIcon from "../../../accounts/ProfileIcon";
import { useAuth } from "../../../../contexts/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import auth from "../../../../config/firebase";
import axios from "axios";
import getAuthHeaders from "../../../../utils/Helpers";
import Table from "../../../Table";
import FileUpload from "../../../FileUpload";

export default function CollectionCreatorDetails({
  setDone,
  setLoading,
  setError,
}) {
  const { currentUser } = useAuth();
  const [tags, setTags] = useState(new Set());
  const [tagInput, setTagInput] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [owners, setOwners] = useState([]);
  const [files, setFiles] = useState([]);
  const [suggestedTags, setSuggestedTags] = useState([]);
  console.log("🚀 ~ suggestedTags:", suggestedTags);

  useEffect(() => {
    const getTags = async () => {
      const config = await getAuthHeaders();

      const result = await axios.get(
        process.env.REACT_APP_SERVER_URL + "/get-tags",
        config
      );
      console.log("🚀 ~ useEffect ~ result:", result);
      setSuggestedTags(result.data);
    };
    getTags();
  }, []);

  const filterKeys = [];

  const renderKey = (key, value) => {
    if (key === "creator") {
      return <ProfileIcon user={value} color="black"></ProfileIcon>;
    }
    if (key === "sharedWith") {
      return value.map((user) => (
        <ProfileIcon user={user} color="black"></ProfileIcon>
      ));
    }
    if (key === "lastUpdated") {
      return new Date(value).toLocaleString();
    }
    if (key === "tags") {
      return value.join(", ");
    }
    return JSON.stringify(value);
  };

  const updateTags = (input) => {
    if (input.includes("\n")) {
      const before = input.split("\n")[0];
      const after = input.split("\n")[1];
      if (before.length > 0) {
        setTags(tags.add(before));
      }
      setTagInput(after);
    } else {
      setTagInput(input);
    }
  };

  const submitCollection = async () => {
    const user = auth.currentUser;
    const token = user && (await user.getIdToken());
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "multipart/form-data",
      },
    };

    const data = new FormData();
    data.append("name", name);
    data.append("description", description);
    data.append("tags", JSON.stringify([...tags]));
    data.append("owners", JSON.stringify(owners));
    data.append("creator", currentUser.uid);
    files.forEach((file) => {
      data.append(file.name, file);
    });

    setLoading(true);

    const response = await axios.post(
      process.env.REACT_APP_SERVER_URL + "/create-collection",
      data,
      config
    );
    if (response.status === 200) {
      setLoading(false);
      setDone(true);
    } else {
      setLoading(false);
      setError(true);
    }
  };

  return (
    <TabPages
      titles={["INFORMATION", "ADD ITEMS"]}
      submitButton={
        <Button onClick={() => submitCollection()}>CREATE COLLECTION</Button>
      }
    >
      <div class="h-full pb-24 bg-white-dark border-grey border-t-2 grid grid-cols-2 grid-rows-2">
        <div class="flex items-center justify-center">
          <div>
            <div class="text-lg font-bold m-4">
              Collection name:<span class="m-2 text-red">*</span>
            </div>
            <input
              class="joyride-collection-name border-2 border-grey rounded-lg w-96 h-16 p-2"
              placeholder="Enter the name of the collection"
              defaultValue={"Collection " + new Date().toLocaleString()}
              onChange={(e) => setName(e.target.value)}
              value={name}
            ></input>
          </div>
        </div>
        <div class="flex items-center justify-center">
          <div class="w-1/2">
            <div class="text-lg font-bold m-4">Add tags:</div>
            <div class="mb-4" key={tags}>
              {[...tags].map((tag) => (
                <span class="ml-2 bg-blue-dark rounded-full text-white p-2">
                  {tag}
                  <span
                    class="ml-2 cursor-pointer"
                    onClick={() => {
                      const newTags = new Set([...tags]);
                      newTags.delete(tag);
                      setTags(newTags);
                    }}
                  >
                    <FontAwesomeIcon icon={faXmark}></FontAwesomeIcon>
                  </span>
                </span>
              ))}
            </div>
            <textarea
              class="joyride-collection-tags border-2 border-grey rounded-lg w-96 h-16 p-2"
              placeholder="Add a tag"
              onChange={(e) => updateTags(e.target.value)}
              value={tagInput}
            ></textarea>
            Type a tag then press 'Enter' to add.
            <div>
              Suggested Tags:{" "}
              {suggestedTags
                .filter((suggestedTag) => !tags.has(suggestedTag))
                .map((tag) => (
                  <span
                    onClick={() => setTags(new Set([...tags.add(tag)]))}
                    class="ml-2 rounded-full bg-white border p-2 cursor-pointer"
                  >
                    {tag}
                  </span>
                ))}
            </div>
          </div>
        </div>
        <div class="flex items-center justify-center">
          <div>
            <div class="text-lg font-bold m-4">Description:</div>
            <textarea
              class="joyride-collection-description border-2 border-grey rounded-lg w-96 h-32 p-2"
              placeholder="Enter a short description"
              onChange={(e) => setDescription(e.target.value)}
              value={description}
            ></textarea>
          </div>
        </div>
        <div class="flex items-center justify-center">
          <div>
            <div class="text-lg font-bold m-4">Owners:</div>
            <div class="border-2 border-grey rounded-lg w-96 h-16 p-2">
              <ProfileIcon user={currentUser} color="black" bold></ProfileIcon>
            </div>
          </div>
        </div>
      </div>
      <div class="h-full pb-24 bg-white-dark border-grey border-t-2 p-4">
        <div class="flex">
          <div class="w-3/4 mr-4">
            <div class="text-blue-light font-bold text-lg">ADDED FILES</div>
            {files.length > 0 && <Table data={files}></Table>}
          </div>
          <div class="w-1/4 mr-4">
            <FileUpload setFiles={setFiles} files={files} />
          </div>
        </div>
      </div>
    </TabPages>
  );
}
