import React from "react";

export default function NewTable({ data }) {
  console.log("🚀 ~ NewTable ~ data:", data);
  if (!data || data.length === 0) return <div></div>;
  return (
    <table class="w-full">
      <thead class="bg-grey-light w-full">
        <tr>
          {Object.keys(data[0]).map((column) => (
            <th class="px-6 py-3">{column}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, i) => (
          <tr>
            {Object.values(row).map((data) => (
              <td class={`h-12 ${i % 2 === 1 ? "bg-grey-light" : ""}`}>
                <a href={"/folder/" + row._id}>{JSON.stringify(data)} </a>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
