import React, { useEffect, useState } from "react";
import Button from "../../../Button";
import { Link } from "react-router-dom";
import Status from "../../../Status";
import CheckCreatorDetails from "./CheckCreatorDetails";
import Modal from "../../../Modal";
import Table from "../../../Table";
import { getCollections } from "../../../../utils/Requests";
import { useAuth } from "../../../../contexts/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder } from "@fortawesome/free-solid-svg-icons";
// import CheckCreatorLoading from "./CheckCreatorLoading";

export default function CheckCreator({ from }) {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [error, setError] = useState(false);
  const [primaryModal, setPrimaryModal] = useState(false);
  const [referenceModal, setReferenceModal] = useState(false);
  const [collections, setCollections] = useState(null);
  const [selectedPrimary, setSelectedPrimary] = useState(null);
  const [selectedReference, setSelectedReference] = useState([]);
  console.log("🚀 ~ CheckCreator ~ selected:", selectedPrimary);
  console.log("🚀 ~ CheckCreator ~ collections:", collections);

  useEffect(() => {
    if (done) {
      window.location.href = "/";
    }
  }, [done]);

  useEffect(() => {
    const fetchCollections = async () => {
      const fetchedCollections = await getCollections();
      setCollections(fetchedCollections);
    };
    fetchCollections();
  }, []);

  const renderDataFunction = (key, value) => {
    if (key === "creator") {
      const username = value.displayName || value.email;
      const currentUsername = currentUser.displayName || currentUser.email;
      return username === currentUsername ? "Me" : username;
    }
    if (key === "lastUpdated") {
      return new Date(value).toLocaleString();
    }
    if (key === "tags") {
      return value.join(", ");
    }
    return value;
  };

  const ModalHeader = () => (
    <div class="text-left">
      <div class="text-blue-dark text-xl font-bold flex">
        <div class="mr-2">
          <FontAwesomeIcon icon={faFolder}></FontAwesomeIcon>
        </div>
        LIST OF COLLECTIONS
      </div>
      The collections created by you and shared to you are listed here
    </div>
  );

  return (
    <>
      <Modal isOpen={primaryModal} setIsOpen={setPrimaryModal} large>
        <div class="mt-12 w-full bg-white h-full text-black p-4">
          <ModalHeader />
          {collections && (
            <>
              <Table
                data={collections.filter((c) => !selectedReference.includes(c))}
                filter={[
                  "_id",
                  "description",
                  "owners",
                  "link",
                  "history",
                  "items",
                  "sharedWith",
                  "files",
                  "size",
                ]}
                renderData={renderDataFunction}
                textColor="black"
                selected={selectedPrimary}
                setSelected={setSelectedPrimary}
              />
              <div class="flow-root mt-8">
                <div class="float-left"></div>
                <div class="float-right w-60">
                  <Button
                    onClick={() => setPrimaryModal(false)}
                    disabled={!selectedPrimary}
                  >
                    ADD TO LIST
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
      </Modal>
      <Modal isOpen={referenceModal} setIsOpen={setReferenceModal} large>
        <div class="mt-12 w-full bg-white h-full text-black p-4">
          {collections && (
            <>
              <Table
                data={collections.filter(
                  (c) => selectedPrimary && c._id !== selectedPrimary._id
                )}
                filter={[
                  "_id",
                  "description",
                  "owners",
                  "link",
                  "history",
                  "items",
                  "sharedWith",
                  "files",
                  "size",
                ]}
                renderData={renderDataFunction}
                textColor="black"
                selected={selectedReference}
                setSelected={setSelectedReference}
                multiSelect
              />
              <div class="flow-root mt-8">
                <div class="float-left">
                  Number of collections selected:{" "}
                  {selectedReference.length > 0
                    ? selectedReference.length
                    : "None"}
                </div>
                <div class="float-right w-60">
                  <Button
                    onClick={() => setReferenceModal(false)}
                    disabled={selectedReference.length === 0}
                  >
                    ADD TO LIST
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
      </Modal>

      <div class="my-6 mx-3">
        <div class="grid grid-cols-3 mb-12">
          <div class="w-60">
            <Link to={from || "/"}>
              <Button secondary>{`BACK TO ${
                from === "/checks" ? "CHECKS" : "HOME"
              }`}</Button>
            </Link>
          </div>
          <div class="text-center text-blue-light font-bold text-xl">
            PREPARING FOR CHECK
          </div>
          <div class="flow-root">
            <div class="float-right">
              <Status includeHeader status="Preparing" />
            </div>
          </div>
        </div>
        {!loading && !done && (
          <CheckCreatorDetails
            setLoading={setLoading}
            setDone={setDone}
            setError={setError}
            setPrimaryModal={setPrimaryModal}
            selectedPrimary={selectedPrimary}
            setReferenceModal={setReferenceModal}
            selectedReference={selectedReference}
          />
        )}
        {/* {loading && <CheckCreatorLoading />} */}
      </div>
    </>
  );
}
