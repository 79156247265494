import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Button from "../../Button";
import Status from "../../Status";
import {
  addSensitivity,
  getCheck,
  getCheckProgress,
} from "../../../utils/Requests";
import Table from "../../Table";
import Loading from "../../Loading";

export default function CheckDetails() {
  const { checkId } = useParams();
  // const checkId = window.location.pathname.split("/").pop();
  const [progress, setProgress] = useState(null);
  const [check, setCheck] = useState(null);
  console.log("🚀 ~ CheckDetails ~ check:", check);
  const [sensitivity, setSensitivity] = useState(null);
  console.log("🚀 ~ getCheckDetails ~ sensitivity:", sensitivity);

  // console.log(
  //   "🚀 ~ CheckDetails ~ check:",
  //   check && Object.keys(check.items[0])
  // );
  console.log("🚀 ~ CheckDetails ~ progress:", progress);

  useEffect(() => {
    const getProgress = async () => {
      const result = await getCheckProgress(checkId);
      setProgress(result);
      setTimeout(getProgress, 5000);
    };
    getProgress();
  }, []);

  useEffect(() => {
    if (sensitivity) {
      addSensitivity(checkId, sensitivity);
    }
  }, [sensitivity]);

  useEffect(() => {
    console.log("progress", progress);
    if (progress) {
      const getCheckDetails = async () => {
        const result = await getCheck(checkId);
        console.log("🚀 ~ getCheckDetails ~ result:", result);
        setCheck(result);
        setSensitivity(result?.sensitivity ? result.sensitivity : sensitivity);
      };
      getCheckDetails();
    }
  }, [progress?.progress]);

  const renderDataFunction = (key, value) => {
    if (key === "scores") {
      console.log(value);
      console.log(Object.values(value));
      return (
        Math.round(
          Object.values(value).reduce((a, b) => Math.max(a, b), -Infinity) * 100
        ) + "%"
      );
    }
    return value;
  };

  return (
    <div class="my-6 mx-3">
      <div class="grid grid-cols-3 mb-12">
        <div class="w-60">
          <Link to="/">
            <Button secondary>BACK TO HOME</Button>
          </Link>
        </div>
        <div class="text-center text-blue-light font-bold text-xl">
          {progress && progress.name}
        </div>
        <div class="flow-root">
          <div class="float-right">
            {progress && (
              <Status
                includeHeader
                status={
                  progress.progress === 1
                    ? "Completed"
                    : progress.progress === 0
                    ? "Preparing"
                    : "Running"
                }
              />
            )}
          </div>
        </div>
      </div>
      {progress && (
        <div class="text-2xl font-bold text-blue text-center">
          {Math.round(progress.progress * 1000) / 10}%
          <div class="bg-grey-light w-full h-4 rounded-full">
            <div
              style={{
                width: `${progress.progress * 100}%`,
                transition: "all 1s",
              }}
              class="bg-blue-light h-4 rounded-full"
            ></div>
          </div>
          {progress.progress !== 1 ? (
            progress.all ? (
              <>
                RUNNING CHECK...
                <Loading size="5x"></Loading>
                <>
                  Comparing items ( {progress.done} / {progress.all} )<br></br>
                  Estimated time remaining: {progress.timeRemaining}
                </>
              </>
            ) : (
              // "PREPARING CHECKS..."
              <>STARTING CHECK...</>
            )
          ) : (
            "CHECK COMPLETE"
          )}
        </div>
      )}
      {check && check.items.filter((item) => item.done).length > 0 && (
        <>
          <div class="text-blue-dark ml-2">
            <label for="sensitivity">Sensitivity</label>
            <input
              type="range"
              id="sensitivity"
              name="sensitivity"
              min="2"
              max="10"
              onChange={(e) => setSensitivity(e.target.value)}
              value={sensitivity}
            />
          </div>
          <br></br>
          Completed items {
            check.items.filter((item) => item.done).length
          } / {check.items.length}
          <Table
            data={check.items
              .filter((item) => item.done)
              .map((item) => ({
                ...item,
                link: window.location.href + "/" + item._id,
                // score: Math.round(
                //   ([
                //     ...new Set(
                //       Object.values(check.matches[item._id])
                //         .map((item) =>
                //           Object.values(item.matches)
                //             .filter((match) => {
                //               return (
                //                 !(check.exclusions || []).includes(match.word) &&
                //                 match.indexes.length > 10 - sensitivity
                //               );
                //             })
                //             .map((match) => match.indexes)
                //             .flat()
                //         )
                //         .flat()
                //     ),
                //   ].length /
                //     check.items.find((checkItem) => checkItem._id === item._id)
                //       .length) *
                //     100
                // ),
              }))}
            linkKey="link"
            filter={["_id", "link", "scores", "done"]}
            renderData={renderDataFunction}
          />
          {check?.matchMap &&
            Object.keys(check?.matchMap).map((match) => (
              <div>
                {match.substring(0, Math.min(40, match.length))} -{" "}
                {check.matchMap[match].length}
              </div>
            ))}
        </>
      )}
      {/* {!loading && !done && (
        <CheckCreatorDetails
          setLoading={setLoading}
          setDone={setDone}
          setError={setError}
          setPrimaryModal={setPrimaryModal}
          selectedPrimary={selectedPrimary}
          setReferenceModal={setReferenceModal}
          selectedReference={selectedReference}
        />
      )} */}
      {/* {loading && <CheckCreatorLoading />} */}
    </div>
  );
}
