import React from "react";

export default function Button({
  type,
  disabled,
  children,
  onClick = () => {},
  secondary,
  red,
  white,
}) {
  if (secondary) {
    return (
      <button
        onClick={() => onClick()}
        class={`${
          disabled
            ? "border-grey text-grey cursor-not-allowed"
            : " border-blue-light  hover:bg-blue-light hover:text-white"
        } w-full p-2 text-md border-2 text-blue-light rounded-md`}
        type={type}
        disabled={disabled}
      >
        {children}
      </button>
    );
  }
  if (red) {
    return (
      <button
        onClick={() => onClick()}
        class={`${
          disabled
            ? "border-grey text-grey cursor-not-allowed"
            : " border-red  hover:bg-red hover:text-white"
        } w-full p-2 text-md border-2 text-red rounded-md`}
        type={type}
        disabled={disabled}
      >
        {children}
      </button>
    );
  }
  if (white) {
    return (
      <button
        onClick={() => onClick()}
        class={`${
          disabled
            ? "border-grey text-grey cursor-not-allowed"
            : "hover:bg-blue-light hover:text-white"
        } w-full p-2 text-md text-blue-light rounded-md bg-white`}
        type={type}
        disabled={disabled}
      >
        {children}
      </button>
    );
  }

  return (
    <button
      onClick={() => onClick()}
      class={`${
        disabled
          ? "bg-grey cursor-not-allowed"
          : "bg-blue-light hover:bg-blue-dark"
      } w-full p-4 text-md text-white rounded-xl`}
      type={type}
      disabled={disabled}
    >
      {children}
    </button>
  );
}
