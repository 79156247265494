import React, { useEffect, useState } from "react";
import { getChecks } from "../../utils/Requests";

export default function ChecksTab({ folderId }) {
  const [checks, setChecks] = useState([]);

  useEffect(() => {
    const getChecksList = async () => {
      const result = await getChecks(folderId);
      setChecks(result);
    };
    getChecksList();
  }, []);

  return (
    <div>
      {checks.map((check) => (
        <div
          onClick={async () => {
            window.location.href = "/checks/" + check._id;
          }}
          style={{ cursor: "pointer" }}
        >
          Check {check._id}: {check.name}
        </div>
      ))}
    </div>
  );
}
