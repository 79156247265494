import React, { useEffect, useState } from "react";
import {
  addExclusions,
  addSensitivity,
  getCheck,
  getCollection,
  getFolder,
  getItem,
} from "../../utils/Requests";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleDown,
  faChevronCircleUp,
  faFolder,
} from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";

export default function ItemResults() {
  const { checkId, primaryItemId } = useParams();
  const [check, setCheck] = useState(null);
  console.log("🚀 ~ ItemResults ~ check:", check?.matchMap);
  const [collection, setCollection] = useState(null);
  const [primaryItem, setPrimaryItem] = useState(null);
  const [referenceItemId, setReferenceItemId] = useState(null);
  const [referenceItem, setReferenceItem] = useState(null);
  const [comparisons, setComparisons] = useState([]);
  const [visualization, setVisualization] = useState("default");
  const [currentScore, setCurrentScore] = useState(0);

  const [clusterExpanded, setClusterExpanded] = useState(true);
  const [visualizationExpanded, setVisualizationExpanded] = useState(true);

  const [exclusions, setExclusions] = useState([]);
  const [sensitivity, setSensitivity] = useState(null);
  console.log("🚀 ~ ItemResults ~ sensitivity:", sensitivity);
  console.log("🚀 ~ ItemResults ~ exclusions:", exclusions);

  const [checkMatches, setCheckMatches] = useState({});

  const [contextMenu, setContextMenu] = useState({
    position: {
      x: 0,
      y: 0,
    },
    toggled: false,
    target: null,
  });

  console.log("🚀 ~ ItemResults ~ contextMenu:", contextMenu);

  const ContextMenu = () => {
    if (!contextMenu.toggled || !contextMenu.target) {
      return null;
    }
    return (
      <menu
        style={{
          position: "absolute",
          backgroundColor: "lightgrey",
          top: contextMenu.position.y + 2,
          left: contextMenu.position.x + 2,
        }}
      >
        <button
          onClick={() =>
            exclusions.includes(contextMenu.target)
              ? setExclusions(
                  exclusions.filter((item) => item !== contextMenu.target)
                )
              : setExclusions([...exclusions, contextMenu.target])
          }
        >
          Exclude Once
        </button>
        {check?.matchMap[contextMenu.target].length > 1 && (
          <>
            <br></br>
            <button>
              Exclude {check?.matchMap[contextMenu.target].length} Times
            </button>
          </>
        )}
        <br></br>
        <a
          href={'https://www.google.com/search?q="' + contextMenu.target + '"'}
          // href={"https://www.google.com/search?q=" + contextMenu.target + ""}
          target="_blank"
        >
          {'Search for "' + contextMenu.target + '"'}
        </a>
      </menu>
    );
  };

  useEffect(() => {
    const getItemDetails = async () => {
      const result = await getItem(primaryItemId);
      console.log("🚀 ~ getItemDetails ~ result:", result);
      setPrimaryItem(result);
      setCheckMatches({
        ...checkMatches,
        [primaryItemId]: result.matches,
      });
    };
    getItemDetails();
    const getCheckDetails = async () => {
      const result = await getCheck(checkId);
      console.log("🚀 ~ getCheckDetails ~ result:", result);
      setCheck(result);
      setExclusions(result?.exclusions || exclusions);
      setSensitivity(result?.sensitivity ? result.sensitivity : sensitivity);
    };
    getCheckDetails();
  }, []);

  useEffect(() => {
    if (exclusions.length > 0) {
      addExclusions(checkId, exclusions);
    }
  }, [exclusions]);

  useEffect(() => {
    if (sensitivity) {
      addSensitivity(checkId, sensitivity);
    }
  }, [sensitivity]);

  useEffect(() => {
    if (!check) {
      return;
    }
    const getCollectionDetails = async () => {
      const result = await getFolder(check.primaryCollection);
      setCollection(result);
    };
    getCollectionDetails();
  }, [check]);

  useEffect(() => {
    if (!primaryItemId || !check) {
      return;
    }
    if (!referenceItemId) {
      setCurrentScore(
        Math.round(
          ([
            ...new Set(
              Object.values(checkMatches[primaryItemId])
                .map((item) =>
                  Object.values(item.matches)
                    .filter((match) => {
                      return (
                        !exclusions.includes(match.word) &&
                        match.indexes.length > 10 - sensitivity
                      );
                    })
                    .map((match) => match.indexes)
                    .flat()
                )
                .flat()
            ),
          ].length /
            primaryItem?.content?.length) *
            100
        )
      );
    } else {
      setCurrentScore(
        Math.round(
          ([
            ...new Set(
              Object.values(
                checkMatches[primaryItemId][referenceItemId].matches
              )
                .filter((match) => {
                  return (
                    !exclusions.includes(match.word) &&
                    match.indexes.length > 10 - sensitivity
                  );
                })
                .map((item) => item.indexes)
                .flat()
            ),
          ].length /
            primaryItem?.content?.length) *
            100
        )
      );
    }
  }, [sensitivity, exclusions]);

  useEffect(() => {
    if (!primaryItem) {
      return;
    }
    if (!referenceItemId) {
      setReferenceItem(null);
      setCurrentScore(
        Math.round(
          ([
            ...new Set(
              Object.values(checkMatches[primaryItemId])
                .map((item) =>
                  Object.values(item.matches)
                    .filter((match) => {
                      return (
                        !exclusions.includes(match.word) &&
                        match.indexes.length > 10 - sensitivity
                      );
                    })
                    .map((match) => match.indexes)
                    .flat()
                )
                .flat()
            ),
          ].length /
            primaryItem?.content?.length) *
            100
        )
      );
      setVisualization("default");
      return;
    }
    setVisualization("textural");
    const getCollectionDetails = async (id) => {
      const result = await getFolder(id);
      return result.name;
    };
    const getItemDetails = async () => {
      const result = await getItem(referenceItemId);
      setReferenceItem({
        ...result,
        collectionName: await getCollectionDetails(result.folder),
      });
      setCheckMatches({
        ...checkMatches,
        [referenceItemId]: result.matches,
      });
      // setCurrentScore(
      //   Math.round(checkMatches[primaryItemId][referenceItemId].score * 100)
      // );
      setCurrentScore(
        Math.round(
          ([
            ...new Set(
              Object.values(
                checkMatches[primaryItemId][referenceItemId].matches
              )
                .filter((match) => {
                  return (
                    !exclusions.includes(match.word) &&
                    match.indexes.length > 10 - sensitivity
                  );
                })
                .map((item) => item.indexes)
                .flat()
            ),
          ].length /
            primaryItem?.content?.length) *
            100
        )
      );
    };
    getItemDetails();
  }, [referenceItemId]);

  useEffect(() => {
    if (!primaryItem || !check) {
      return;
    }
    const newComparisons = {};

    Object.keys(checkMatches[primaryItemId]).forEach((secondaryItemId) => {
      newComparisons[secondaryItemId] = {
        name: secondaryItemId,
        _id: secondaryItemId,
        score: checkMatches[primaryItemId][secondaryItemId].score,
      };
    });

    setCurrentScore(
      Math.round(
        ([
          ...new Set(
            Object.values(checkMatches[primaryItemId])
              .map((item) =>
                Object.values(item.matches)
                  .filter((match) => {
                    return (
                      !exclusions.includes(match.word) &&
                      match.indexes.length > 10 - sensitivity
                    );
                  })
                  .map((match) => match.indexes)
                  .flat()
              )
              .flat()
          ),
        ].length /
          primaryItem?.content?.length) *
          100
      )
    );

    // primaryItem.content.forEach((word) => {
    //   if (word.matches && word.matches.length > 0) {
    //     word.matches
    //       .filter((match) => match.checkId === check._id)
    //       .forEach((match) => {
    //         newComparisons[match.item._id] = {
    //           ...match.item,
    //           score: primaryItem.scores[match.item._id],
    //         };
    //       });
    //   }
    // });
    console.log("newComparisons", newComparisons);
    setComparisons(
      Object.values(newComparisons).sort((a, b) => b.score - a.score)
    );
  }, [primaryItem, check]);

  const Node = ({ item }) => {
    if (!item) {
      return <div></div>;
    }
    const color =
      item._id === primaryItemId ? "#005391" : findMatchColor(item._id);
    const percentage =
      item._id === primaryItemId
        ? 100
        : Math.round(primaryItem.scores[item._id] * 100);
    return (
      <div class="text-center font-bold" style={{ color: color }}>
        <div class="h-36 w-24 rounded-lg bg-grey-light border-2 border-black m-auto">
          <div
            style={{ height: `${100 - percentage}%` }}
            class={`w-22 rounded-lg`}
          ></div>
          <div
            style={{ height: `${percentage}%`, backgroundColor: color }}
            class={`w-22 rounded-lg`}
          ></div>
        </div>
        {item.name}
        <br></br>
        {item._id !== primaryItemId && `${percentage}%`}
      </div>
    );
  };

  const NodeView = () => (
    <div class="w-full h-full grid grid-cols-2">
      <div></div>
      <div class="flex items-center justify-center">
        <Node item={comparisons?.[0]} color="red"></Node>
      </div>
      <div class="flex items-center justify-center">
        <Node item={primaryItem} color="blue"></Node>
      </div>
      <div class="flex items-center justify-center">
        <Node item={comparisons?.[1]} color="yellow"></Node>
      </div>
      <div></div>
      <div class="flex items-center justify-center">
        <Node item={comparisons?.[2]} color="green"></Node>
      </div>
    </div>
  );

  const findMatchColor = (itemId) => {
    let color = "white";
    primaryItem.content.forEach((word) => {
      if (word.matches && word.matches.length > 0) {
        word.matches
          .filter((match) => match.checkId === check._id)
          .forEach((match) => {
            if (match.item._id === itemId) {
              color = match.color;
            }
          });
      }
    });
    return color;
  };

  const findFilterMatch = (matches, filterId) => {
    return matches
      .filter((match) => match.checkId === check._id)
      .find((match) => match.item._id === filterId);
  };

  const renderDocument = (item, filterId) => {
    if (!check || !item) {
      return;
    }
    console.log("🚀 ~ renderDocument ~ item:", item);
    console.log("🚀 ~ renderDocument ~ filterId:", filterId);

    const indexMap = {};
    let matches = [];
    if (filterId) {
      matches = checkMatches[item._id][filterId]
        ? Object.values(checkMatches[item._id][filterId].matches)
            .filter((match) => {
              return (
                !exclusions.includes(match.word) &&
                match.indexes.length > 10 - sensitivity
              );
            })
            .map((item) => item.indexes)
            .flat()
        : [];
      Object.values(checkMatches[item._id][filterId].matches).forEach(
        (match) => {
          match.indexes.forEach((index) => {
            indexMap[index] = match.word;
          });
        }
      );
    } else {
      console.log("item._id", item._id);
      console.log("checkMatches", checkMatches);
      matches = Object.values(checkMatches[item._id])
        .map((item) =>
          Object.values(item.matches)
            .filter((match) => {
              return (
                !exclusions.includes(match.word) &&
                match.indexes.length > 10 - sensitivity
              );
            })
            .map((match) => match.indexes)
            .flat()
        )
        .flat();

      Object.values(checkMatches[item._id]).forEach((item) =>
        Object.values(item.matches).forEach((match) =>
          match.indexes.forEach((index) => {
            indexMap[index] = match.word;
          })
        )
      );
    }
    console.log("matches", matches);
    console.log("indexMap", indexMap);
    console.log(
      "item?.rawContent",
      item?.rawContent
        ?.replace(/[^A-Za-z\s]/g, "")
        .replace(/(\r\n|\n|\r)/gm, " ")
        .split(" ")
        .filter((str) => str.length > 0)
    );

    return (
      <div class="w-[45%] h-[90%] p-6 bg-white text-sm overflow-auto mx-4">
        <div class="flow-root">
          <div class="float-left font-bold text-blue-light">{item?.name}</div>
          <div class="float-right text-xs font-bold text-blue-dark">
            {item?.collectionName?.toUpperCase() ||
              collection?.name?.toUpperCase()}
          </div>
          <hr class="text-grey mt-8 mb-6"></hr>
        </div>
        <p>
          {item?.rawContent
            ?.replace(/[^A-Za-z\s]/g, "")
            .replace(/(\r\n|\n|\r)/gm, " ")
            .split(" ")
            .filter((str) => str.length > 0)
            .map((section, index) =>
              matches && matches.length > 0 ? (
                <span
                  style={{
                    backgroundColor: matches.includes(index + 1)
                      ? "lightblue"
                      : exclusions.includes(indexMap[index + 1])
                      ? "lightgrey"
                      : "white",
                    cursor: matches.includes(index + 1)
                      ? "pointer"
                      : exclusions.includes(indexMap[index + 1])
                      ? "pointer"
                      : "default",

                    // backgroundColor:
                    //   !filterId || findFilterMatch(matches, filterId)
                    //     ? findFilterMatch(matches, filterId)?.color ||
                    //       matches.filter((match) => match.checkId === check._id)[0]
                    //         ?.color
                    //     : "white",
                    //   (data2?.name &&
                    //   matches
                    //     .map((match) => match.doc)
                    //     .includes(data2.name)
                    //     ? "bb"
                    //     : "30"),
                  }}
                  // style={{
                  //   backgroundColor: matches[0].color + "80",
                  // }}
                  onContextMenu={(e) => {
                    e.preventDefault();
                    setContextMenu({
                      position: {
                        x: e.clientX,
                        y: e.clientY,
                      },
                      toggled: true,
                      target: indexMap[index + 1],
                    });
                  }}
                  // onClick={() =>
                  //   exclusions.includes(indexMap[index])
                  //     ? setExclusions(
                  //         exclusions.filter((item) => item !== indexMap[index])
                  //       )
                  //     : matches.includes(index)
                  //     ? setExclusions([...exclusions, indexMap[index]])
                  //     : {}
                  // }
                  key={index}
                >
                  {section.includes("\n") ? (
                    <>
                      {" "}
                      <br></br>
                      {section + " "}
                      <br></br>
                    </>
                  ) : (
                    section + " "
                  )}
                </span>
              ) : section.includes("\n") ? (
                <>
                  <br></br>

                  {section + " "}
                  <br></br>
                </>
              ) : (
                section + " "
              )
            )}
        </p>
        <br></br>
        {/* <h2>{Math.round(data?.score1 * 100)}% similar</h2> */}
      </div>
    );
  };

  const Expand = ({ children, header, expanded, setExpanded }) => {
    return (
      <div>
        <div
          class="w-full h-14 text-white bg-blue-dark py-4 px-6 text-sm font-bold cursor-pointer"
          onClick={() => setExpanded(!expanded)}
        >
          <div class="flow-root">
            <div class="float-left">{header}</div>
            <div class="float-right">
              <FontAwesomeIcon
                icon={expanded ? faChevronCircleDown : faChevronCircleUp}
                size="2x"
              ></FontAwesomeIcon>
            </div>
          </div>
        </div>
        {expanded && children}
      </div>
    );
  };

  const getScoreLevel = (score) => {
    if (score > 66) {
      return "High";
    }
    if (score > 33) {
      return "Medium";
    }
    return "Low";
  };

  const getScoreColor = (score) => {
    if (score > 66) {
      return "red";
    }
    if (score > 33) {
      return "orange";
    }
    return "green";
  };

  return (
    <div class="flex h-[calc(100vh-64px-64px)]">
      <ContextMenu></ContextMenu>
      <div class="w-full bg-grey-light">
        <div class="w-full h-full">
          <div class="w-full h-full flex justify-center items-center">
            {visualization === "node" ? (
              <NodeView></NodeView>
            ) : (
              <>
                {primaryItem &&
                  check &&
                  renderDocument(primaryItem, referenceItemId)}
                {referenceItem && (
                  <div class="border-2 border-grey h-full"></div>
                )}
                {referenceItem &&
                  check &&
                  renderDocument(referenceItem, primaryItemId)}
              </>
            )}
          </div>
          <div class="w-full h-16 bg-blue-dark mt-auto flex items-center p-4">
            <span class="text-grey font-bold">Word count:</span>
            <span class="text-white">{primaryItem?.content?.length}</span>
            <div class="text-white ml-20">
              <label for="sensitivity">Sensitivity</label>
              <input
                type="range"
                id="sensitivity"
                name="sensitivity"
                min="2"
                max="10"
                onChange={(e) => setSensitivity(e.target.value)}
                value={sensitivity}
              />
            </div>
          </div>
        </div>
      </div>
      <div class="w-96">
        <div class="p-4">
          <div class="text-blue-light font-bold text-sm">
            <FontAwesomeIcon icon={faFolder}></FontAwesomeIcon> Collection name
          </div>
          <div class="text-blue-dark font-bold mt-1">{collection?.name}</div>
        </div>
        <hr class="text-grey my-3"></hr>
        <div class="p-4">
          <div class="text-grey font-bold text-sm">File name</div>
          <div class="text-grey-dark font-bold mt-1">{primaryItem?.name}</div>
        </div>
        <hr class="text-grey my-3"></hr>
        <div class="p-4">
          <div class="flow-root">
            <div class="text-grey-dark font-bold float-left">
              Similarity Score
            </div>
            <div class="text-grey-dark font-bold float-right">
              {currentScore}%
              <span style={{ color: getScoreColor(currentScore) }} class="ml-1">
                ({getScoreLevel(currentScore)})
              </span>
            </div>
          </div>
          <div class="bg-grey-light w-full h-2 rounded-full mt-4">
            <div
              style={{
                width: "100%",
                maskImage: `linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(2,0,36,1) ${currentScore}%, rgba(0,212,255,0) ${currentScore}%)`,
              }}
              class="h-2 rounded-full"
            >
              <div
                style={{
                  width: "100%",
                  background: "linear-gradient(90deg,green,yellow,red)",
                }}
                class="h-2 rounded-full"
              ></div>
            </div>
          </div>
        </div>
        <Expand
          header="ITEMS IN CLUSTER"
          expanded={clusterExpanded}
          setExpanded={setClusterExpanded}
        >
          {comparisons.slice(0, 3).map((item) => (
            <div
              onClick={() =>
                referenceItemId === item._id
                  ? setReferenceItemId(null)
                  : setReferenceItemId(item._id)
              }
            >
              <div class="p-4 cursor-pointer">
                <div
                  class={`flow-root ${
                    referenceItemId === item._id
                      ? "text-grey-dark"
                      : "text-grey"
                  }`}
                >
                  <div class="float-left">{item.name}</div>
                  <div class="font-bold float-right">
                    {Math.round(
                      ([
                        ...new Set(
                          Object.values(
                            checkMatches[primaryItemId][item._id].matches
                          )
                            .filter((match) => {
                              return (
                                !exclusions.includes(match.word) &&
                                match.indexes.length > 10 - sensitivity
                              );
                            })
                            .map((item) => item.indexes)
                            .flat()
                        ),
                      ].length /
                        check.items.find(
                          (checkItem) => checkItem._id === item._id
                        ).length) *
                        100
                    ) + "%"}
                  </div>
                </div>
                <div class="bg-grey-light w-full h-2 rounded-full mt-2">
                  <div
                    style={{
                      width: `${Math.round(
                        ([
                          ...new Set(
                            Object.values(
                              checkMatches[primaryItemId][item._id].matches
                            )
                              .filter((match) => {
                                return (
                                  !exclusions.includes(match.word) &&
                                  match.indexes.length > 10 - sensitivity
                                );
                              })
                              .map((item) => item.indexes)
                              .flat()
                          ),
                        ].length /
                          check.items.find(
                            (checkItem) => checkItem._id === item._id
                          ).length) *
                          100
                      )}%`,
                      // backgroundColor: findMatchColor(item._id),
                      backgroundColor: "lightblue",
                    }}
                    class="h-2 rounded-full"
                  ></div>
                </div>
              </div>
              <hr class="text-grey my-1"></hr>
            </div>
          ))}
        </Expand>
        <Expand
          header="DATA VISUALISATION"
          expanded={visualizationExpanded}
          setExpanded={setVisualizationExpanded}
        >
          <div class="p-4">
            <div
              onClick={() => {
                setVisualization("default");
                setReferenceItemId(null);
              }}
              class="mb-2 cursor-pointer"
            >
              <input
                type="radio"
                name="visualization"
                checked={visualization === "default"}
                class="mr-2"
              />
              Default view
            </div>
            <div
              onClick={() => {
                if (comparisons[0]?._id) {
                  setVisualization("textural");
                  setReferenceItemId(comparisons[0]._id);
                }
              }}
              class="my-2 cursor-pointer"
            >
              <input
                type="radio"
                name="visualization"
                checked={visualization === "textural"}
                class="mr-2"
              />
              Textural Analysis
            </div>
            <div
              onClick={() => setVisualization("node")}
              class="mt-2 cursor-pointer"
            >
              <input
                type="radio"
                name="visualization"
                checked={visualization === "node"}
                class="mr-2"
              />
              Node Analysis
            </div>
          </div>
        </Expand>
      </div>
    </div>
  );
}
