import { Navigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

const WithAdminRoute = ({ children }) => {
  const { currentUser } = useAuth();

  //TODO: implement me
  if (currentUser) {
    return children;
  }

  return <Navigate to="/" />;
};

export default WithAdminRoute;
