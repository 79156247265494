import React, { useEffect, useState } from "react";
import {
  faHouse,
  faFileEdit,
  faRetweet,
} from "@fortawesome/free-solid-svg-icons";
import PageTitle from "../PageTitle";
import Button from "../Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Table from "../Table";
import getAuthHeaders from "../../utils/Helpers";
import axios from "axios";
import Status from "../Status";
import Progress from "../Progress";
import HomepageBanner from "../HomepageBanner";
import NewTable from "../NewTable";
import { getFolders } from "../../utils/Requests";

export default function Home() {
  const [progress, setProgress] = React.useState(null);
  const [folders, setFolders] = useState(null);
  console.log("🚀 ~ Home ~ folders:", folders);

  useEffect(() => {
    // const getProgress = async () => {
    //   const config = await getAuthHeaders();
    //   const result = await axios.get(
    //     process.env.REACT_APP_SERVER_URL + "/get-recent-progress",
    //     config
    //   );
    //   if (!result.data) return;
    //   setProgress(
    //     result.data.map((check) => ({ ...check, link: "/checks/" + check._id }))
    //   );
    //   setTimeout(getProgress, 5000);
    // };
    // getProgress();

    const getFolderData = async () => {
      const response = await getFolders();
      console.log("🚀 ~  ~ response:", response);
      setFolders(response);
    };
    getFolderData();
  }, []);

  // const renderDataFunction = (key, value) => {
  //   if (key === "date") {
  //     return new Date(value).toLocaleString();
  //   }
  //   if (key === "progress") {
  //     return (
  //       <div class="flex items-center h-10">
  //         <div class="mr-2 w-36">
  //           <Status
  //             status={
  //               value === 1
  //                 ? "Completed"
  //                 : value === 0
  //                 ? "Preparing"
  //                 : "Running"
  //             }
  //           ></Status>
  //         </div>
  //         <Progress progress={value}></Progress>
  //       </div>
  //     );
  //   }
  //   return value;
  // };

  return (
    <>
      <HomepageBanner></HomepageBanner>
      <div class="my-6 mx-3">
        <PageTitle title="My Content"></PageTitle>
        <NewTable data={folders}></NewTable>
        {/* <div class="flex justify-center items-center">
        <img class="w-32 py-4 opacity-70" src="/Echo black.png"></img>
      </div>
      <div class="flow-root">
        <div class="w-1/2 float-left">
          <div class="joyride-create-collection m-auto w-80 text-center text-grey-dark">
            <Link to="/new">
              <Button>
                <span class="mr-2">
                  <FontAwesomeIcon icon={faFileEdit}></FontAwesomeIcon>
                </span>
                CREATE COLLECTION
              </Button>
            </Link>
            <p class="mt-4 text-sm">
              Organise and analyse files effortlessly by creating custom
              collections for streamlined assessments.
            </p>
          </div>
        </div>
        <div class="w-1/2 float-left">
          <div class="my-first-step m-auto w-80 text-center text-grey-dark">
            <Link to="/checks/new">
              <Button>
                <span class="mr-2">
                  <FontAwesomeIcon icon={faRetweet}></FontAwesomeIcon>
                </span>
                START CHECK
              </Button>
            </Link>
            <p class="mt-4 text-sm">
              Initiate comprehensive checks on your documents with ease using
              existing collections for quick insights.
            </p>
          </div>
        </div>
      </div>
      <hr class="text-grey my-8"></hr>
      <div class="text-grey-dark text-sm font-bold">RECENT ACTIVITY</div>
      {progress && progress.length > 0 && (
        <Table
          data={progress}
          filter={[
            "_id",
            "referenceCollections",
            "settings",
            "status",
            "creator",
            "owners",
            "link",
            "done",
            "all",
            "currentJob",
            "matches",
            "matchMap",
            "exclusions",
          ]}
          renderData={renderDataFunction}
          linkKey="link"
        ></Table>
      )} */}
        {/* <table class="w-full mt-2 border border-blue-light">
        <thead class="bg-blue-light text-white font-medium">
          <tr>
            {["NAME", "ACTIVITY", "DATE CREATED", "STATUS", "ACTIONS"].map(
              (column) => (
                <th class="px-6 py-3">{column}</th>
              )
            )}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>hello</td>
          </tr>
        </tbody>
      </table> */}
      </div>
    </>
  );
}
