import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../contexts/AuthContext";
import auth from "../../config/firebase";
import axios from "axios";

export default function Profile() {
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);

  const { currentUser, updateUserProfile, setError } = useAuth();
  console.log("🚀 ~ Profile ~ currentUser:", currentUser);

  const uploadImage = async () => {
    const user = auth.currentUser;
    const token = user && (await user.getIdToken());
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "multipart/form-data",
      },
    };
    // setLoading(true);
    const data = new FormData();
    data.append(image.name, image);

    const result = await axios.post(
      process.env.REACT_APP_SERVER_URL + "/upload-image",
      data,
      config
    );
    // setLoading(false);
    console.log("🚀 ~ useEffect ~ result:", result);
    return process.env.REACT_APP_SERVER_URL + "/" + result.data;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      const user = currentUser;

      const profile = {
        displayName: username,
        photoURL: image,
      };
      if (image) {
        profile.photoURL = await uploadImage();
      }
      console.log("🚀 ~ handleFormSubmit ~ profile.image:", image);
      await updateUserProfile(user, profile);
      navigate("/");
    } catch (e) {
      console.log(e);
      setError("Failed to update profile");
    }

    setLoading(false);
  };

  const handleFileRead = async (event) => {
    setImage(event.target.files[0]);
  };

  return (
    <div>
      <div>
        <form onSubmit={handleFormSubmit}>
          <div>
            <input
              id="username"
              name="username"
              type="text"
              autoComplete="username"
              required
              placeholder="Enter a Display Name"
              defaultValue={currentUser.displayName && currentUser.displayName}
              onChange={(e) => setUsername(e.target.value)}
            />
            <input
              id="originalFileName"
              type="file"
              inputProps={{
                accept: "image/*, .xlsx, .xls, .csv, .pdf, .pptx, .pptm, .ppt",
              }}
              required
              label="Document"
              name="originalFileName"
              onChange={(e) => handleFileRead(e)}
              size="small"
              variant="standard"
            />
            <img src={image}></img>
          </div>
          <div>
            <button type="submit" disabled={loading}>
              Update Profile
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
