import React from "react";
import Button from "./Button";

export default function CheckCreator({ folderId }) {
  return (
    <div>
      <div class="h-20 bg-white flex">
        <div class="w-32">
          <Button
            onClick={() => {
              window.location.href = "/folder/" + folderId;
            }}
          >
            Back
          </Button>
        </div>
        Preparing for Check
      </div>
      <div class="my-6 mx-3 "></div>
    </div>
  );
}
