import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Button from "./Button";

export default function Modal({
  children,
  isOpen,
  setIsOpen,
  large,
  white,
  logout,
}) {
  if (white) {
    return (
      <>
        {isOpen && (
          // <div class="pt-20 absolute z-10 w-full bg-blue-dark-transparent">
          <div class="pt-20 fixed left-0 top-0 z-50 w-full h-full bg-blue-dark-transparent">
            <div class="flex flex-row justify-center items-center">
              <div class="w-1/3 h-3/4 mb-96 mt-2 bg-white rounded-md text-black p-8">
                {children}
              </div>
            </div>
            ;
          </div>
        )}
      </>
    );
  }
  if (logout) {
    return (
      <>
        {isOpen && (
          // <div class="pt-20 absolute z-10 w-full bg-blue-dark-transparent">
          <div class="pt-52 fixed left-0 top-0 z-50 w-full h-full bg-blue-dark">
            <div class="flex flex-row justify-center items-center">
              <div class="w-96 h-64 mb-96 mt-2 bg-white rounded-md text-black p-8">
                {children}
              </div>
            </div>
            ;
          </div>
        )}
      </>
    );
  }
  if (large) {
    return (
      <>
        {isOpen && (
          <div class="absolute z-10 w-full bg-grey-transparent">
            <div class="flex flex-row justify-center items-center">
              <div class="w-full mx-20 h-full mb-96 mt-2 bg-blue-dark rounded-lg justify-center items-center text-center text-white">
                <div
                  class="float-right m-2 mr-4 cursor-pointer text-2xl"
                  onClick={() => setIsOpen(false)}
                >
                  <FontAwesomeIcon icon={faXmark}></FontAwesomeIcon>
                </div>
                {children}
              </div>
            </div>
            ;
          </div>
        )}
      </>
    );
  }
  return (
    <>
      {isOpen && (
        <div class="absolute z-10 w-full bg-grey-transparent">
          <div class="flex flex-row justify-center items-center">
            <div class="w-96 h-96 mb-96 mt-2 bg-blue-dark rounded-lg justify-center items-center text-center text-white">
              <div
                class="float-right m-2 mr-4 cursor-pointer text-2xl"
                onClick={() => setIsOpen(false)}
              >
                <FontAwesomeIcon icon={faXmark}></FontAwesomeIcon>
              </div>
              {children}
            </div>
          </div>
          ;
        </div>
      )}
    </>
  );
}
