import React, { useEffect, useState } from "react";
import Button from "../../../Button";
import { Link } from "react-router-dom";
import Status from "../../../Status";
import TabPages from "../../../TabPages";
import ProfileIcon from "../../../accounts/ProfileIcon";
import { useAuth } from "../../../../contexts/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faStopwatch,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import auth from "../../../../config/firebase";
import axios from "axios";
import getAuthHeaders from "../../../../utils/Helpers";
import Table from "../../../Table";
import FileUpload from "../../../FileUpload";

export default function CheckCreatorDetails({
  setDone,
  setLoading,
  setError,
  setPrimaryModal,
  setReferenceModal,
  selectedPrimary,
  selectedReference,
}) {
  console.log("🚀 ~ selectedReference:", selectedReference);
  const minutesPerComparison = 0.00117543344;
  const { currentUser } = useAuth();
  const [title, setTitle] = useState("");
  const [estimatedTime, setEstimatedTime] = useState(null);
  const [settings, setSettings] = useState({
    similarityThreshold: 30,
    minWords: 2,
    internalComparison: true,
  });
  console.log("🚀 ~ settings:", settings);

  useEffect(() => {
    if (selectedPrimary && title === "") {
      setTitle(selectedPrimary.name + " Check");
    }
  }, [selectedPrimary]);

  const pairwiseComparisons = (k) => (k * (k - 1)) / 2;

  useEffect(() => {
    console.log("selectedPrimary", selectedPrimary);
    if (!selectedPrimary) {
      return;
    }
    let comparisons = 0;
    if (selectedPrimary && settings.internalComparison) {
      comparisons += pairwiseComparisons(
        Object.values(selectedPrimary.items).length
      );
      console.log("🚀 ~ useEffect ~ comparisons:", comparisons);
    }
    if (selectedReference) {
      selectedReference.forEach((reference) => {
        comparisons +=
          Object.values(reference.items).length *
          Object.values(selectedPrimary.items).length;
        console.log(
          "🚀 ~ selectedReference.forEach ~ comparisons:",
          comparisons
        );
      });
    }
    const minutes = Math.round(comparisons * minutesPerComparison);
    setEstimatedTime(minutes + " Minute" + (minutes !== 1 ? "s" : ""));
  }, [selectedPrimary, selectedReference, settings.internalComparison]);

  const startCheck = async () => {
    const config = await getAuthHeaders();

    setLoading(true);
    const result = await axios.post(
      process.env.REACT_APP_SERVER_URL + "/create-check",
      {
        name: title,
        primaryCollection: selectedPrimary._id,
        referenceCollections: selectedReference.map((ref) => ref._id),
        settings,
      },
      config
    );
    window.location.href = "/checks/" + result.data;
  };

  return (
    // <TabPages
    //   titles={["SETTING UP", "REPORTS"]}
    //   submitButton={<Button onClick={() => startCheck()}>START CHECK</Button>}
    // >
    <div>
      <div class="h-full pb-6 bg-white-dark border-grey border-t-2 grid grid-cols-2 gap-4 pt-8 p-4">
        <div>
          <div class="text-xl font-bold w-full h-20 flow-root">
            <div class="float-left flex items-center">
              Title:<span class="m-2 text-red">*</span>
            </div>
            <input
              class="border-2 border-grey rounded-lg w-[87%] h-14 p-2 float-right"
              //   placeholder="Enter the name of the collection"
              //   defaultValue={"Collection " + new Date().toLocaleString()}
              onChange={(e) => setTitle(e.target.value)}
              value={title}
            ></input>
          </div>
          <div class="flow-root mt-4">
            <div class="text-lg text-blue-light font-bold float-left mt-4 items-center">
              MAIN COLLECTION
            </div>
            <div class="float-right w-36 font-bold">
              <Button onClick={() => setPrimaryModal(true)} secondary>
                {selectedPrimary ? "CHANGE" : "+ ADD"}
              </Button>
            </div>
          </div>
          <Table
            // headers={["NAME OF THE COLLECTION", "TAG", "FILES", "AUTHOR"]}
            filter={[
              "_id",
              "description",
              "creator",
              "owners",
              "link",
              "history",
              "items",
              "sharedWith",
              "size",
            ]}
            data={
              selectedPrimary
                ? [selectedPrimary]
                : [{ name: "NO MAIN COLLECTION" }]
            }
          ></Table>
          <div class="flex">
            <input
              type="checkbox"
              class="form-checkbox h-5 w-5 mr-2 mt-5"
              checked={settings.internalComparison}
              onChange={(e) =>
                setSettings({
                  ...settings,
                  internalComparison: e.target.checked,
                })
              }
            ></input>
            <div class="mt-4">Compare items within the main collection</div>
          </div>
          <div class="text-lg text-blue-light font-bold tems-center mt-20">
            SET THRESHOLD
          </div>
          Similarity threshold cut-off
          <input
            type="number"
            class="border-2 border-grey rounded-lg w-24 h-12 p-2 mx-2 text-center"
            value={settings.similarityThreshold}
            onChange={(e) => {
              if (e.target.value > 0 && e.target.value < 100) {
                setSettings({
                  ...settings,
                  similarityThreshold: e.target.value,
                });
              }
            }}
          ></input>
          Minimum words to match
          <input
            type="number"
            class="border-2 border-grey rounded-lg w-24 h-12 p-2 mx-2 text-center"
            value={settings.minWords}
            onChange={(e) => {
              if (e.target.value > 1) {
                setSettings({
                  ...settings,
                  minWords: e.target.value,
                });
              }
            }}
          ></input>
        </div>
        <div class="">
          <div class="h-20 mb-4 float-right">
            <div class="text-lg text-blue-light font-bold flex items-center">
              TOTAL ESTIMATED TIME
              <div class="bg-blue-dark w-48 h-12 rounded-lg ml-4 text-white items-center flex justify-center text-xl font-normal">
                <FontAwesomeIcon icon={faStopwatch}></FontAwesomeIcon>
                <span class="ml-2">
                  {estimatedTime ? estimatedTime : "-" + " MINUTES"}
                </span>
              </div>
            </div>
          </div>
          <div class="flow-root items-center mt-24">
            <div class="text-lg text-blue-light font-bold float-left mt-4">
              REFERENCE COLLECTIONS
            </div>
            <div class="float-right w-36 font-bold">
              <Button
                onClick={() => setReferenceModal(true)}
                secondary
                disabled={!selectedPrimary}
              >
                {selectedReference.length > 0 ? "CHANGE" : "+ ADD"}
              </Button>
            </div>
          </div>
          <Table
            headers={["NAME OF THE COLLECTION", "TAG", "ACTION"]}
            filter={[
              "_id",
              "description",
              "creator",
              "owners",
              "link",
              "history",
              "items",
              "sharedWith",
              "files",
              "size",
              "lastUpdated",
            ]}
            renderData={(key, value) => {
              if (key === "action") {
                return (
                  <select
                    name="Action"
                    id="Action"
                    onChange={(e) => {
                      const action = e.target.value;
                      const collectionId = value;
                      setSettings({
                        ...settings,
                        actions: {
                          ...settings.actions,
                          [collectionId]: action,
                        },
                      });
                    }}
                  >
                    <option value="Compare">Compare</option>
                    <option value="Exclude">Exclude</option>
                  </select>
                );
              }

              return value;
            }}
            data={
              selectedReference.length > 0
                ? selectedReference.map((reference) => ({
                    ...reference,
                    action: reference._id,
                  }))
                : [{ name: "NO COLLECTION" }]
            }
          ></Table>
        </div>
      </div>
      <div class="flow-root">
        <div class="float-right w-60 m-8">
          <Button
            onClick={() => startCheck()}
            disabled={!selectedPrimary || title.length === 0}
          >
            START CHECK
          </Button>
        </div>
      </div>
    </div>

    // </TabPages>
  );
}
