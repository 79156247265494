import React, { useEffect, useState } from "react";
import Button from "../Button";
import {
  deleteDatabaseCollection,
  deleteEchoCollection,
  getAllCollections,
  getAllUsers,
  nukeDatabase,
} from "../../utils/AdminRequests";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBomb, faTrash } from "@fortawesome/free-solid-svg-icons";
import ProfileIcon from "../accounts/ProfileIcon";

const nukeDb = async () => {
  const verify = window.confirm(
    `Are you sure you want to nuke the entire db? (this is a *very* destructive action)`
  );
  if (!verify) return;
  const response = await nukeDatabase();
  console.log("🚀 ~ nukeDb ~ response:", response);
  if (response === "done") {
    alert("Database successfully nuked");
  } else {
    alert("Database nuke failed");
  }
};

const deleteDbCollection = async (collection) => {
  const verify = window.confirm(
    `Are you sure you want to delete the ${collection} collection? (this is a destructive action)`
  );
  if (!verify) return;
  const response = await deleteDatabaseCollection(collection);
  console.log("🚀 ~ deleteDbCollection ~ response:", response);
  if (response === "done") {
    alert(collection + " successfully deleted");
  } else {
    alert(collection + " deletion failed");
  }
};

const deleteCollection = async (collection) => {
  console.log("🚀 ~ deleteCollection ~ collection:", collection);
  const verify = window.confirm(
    `Are you sure you want to delete the ${collection.name} collection? (this is a destructive action)`
  );
  if (!verify) return;
  console.log("deleteing");
  const response = await deleteEchoCollection(collection._id);
  console.log("🚀 ~ deleteCollection ~ response:", response);
  if (response.deletedCount) {
    alert(collection.name + " successfully deleted");
    window.location.reload();
  } else {
    alert(collection.name + " deletion failed");
  }
};

export default function Admin() {
  const [users, setUsers] = useState([]);
  const [collections, setCollections] = useState([]);
  console.log("🚀 ~ Admin ~ collections:", collections);

  useEffect(() => {
    const getUsers = async () => {
      const response = await getAllUsers();
      setUsers(response);
    };
    getUsers();
    const getCollections = async () => {
      const response = await getAllCollections();
      setCollections(response);
    };
    getCollections();
  }, []);

  return (
    <div class="m-10 grid grid-cols-2 gap-4">
      <div class="grid grid-cols-1 gap-2">
        <div class="font-bold">Database Management</div>
        <div class="text-blue">Database nuke</div>
        <Button red onClick={() => nukeDb()}>
          Nuke DB <FontAwesomeIcon icon={faBomb}></FontAwesomeIcon>
        </Button>
        <div class="text-blue">Database Collection management</div>
        <div>
          (This does not refer to "Collection" in the RM Echo sense, but in the
          MongoDB sense. These operations are the equivalent of dropping SQL
          tables!)
        </div>
        {["collections", "items", "checks", "work"].map((collection) => (
          <Button red onClick={() => deleteDbCollection(collection)}>
            Delete {collection} Collection
            <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
          </Button>
        ))}
      </div>
      <div class="grid grid-cols-1 gap-2">
        <div class="font-bold">User management</div>
        <div class="text-blue">Users</div>
        <ul>
          {users.map((user) => (
            <li key={user.uid} class="mb-4">
              <div class="flow-root">
                <div class="float-left">
                  <ProfileIcon user={user} color="blue-dark"></ProfileIcon>
                  <div>{user.email}</div>
                  <div>User since: {user.metadata.creationTime}</div>
                  <div>Last active: {user.metadata.lastRefreshTime}</div>
                </div>
                <div class="float-right">
                  <Button red>Delete User</Button>
                  <Button secondary>Disable User</Button>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div class="grid grid-cols-1 gap-2 mt-8">
        <div class="font-bold">Collection management</div>
        <div class="text-blue">Collections</div>
        <ul>
          {collections.map((collection) => (
            <li key={collection._id} class="mb-4">
              <div class="flow-root border border-blue p-2 rounded-lg">
                <div class="float-left">
                  <div class="flow-root">
                    <div class="float-left">
                      <div class="text-xs">{collection._id}</div>
                      <div class="text-blue font-bold">{collection.name}</div>
                      <ProfileIcon
                        user={collection.creator}
                        color="blue-dark"
                      ></ProfileIcon>
                    </div>
                    <div class="float-right ml-6">
                      <div>Files: {collection.files}</div>
                      <div>Size: {collection.size}</div>
                      <div class="text-red">
                        Used in {collection.checks} check(s)
                      </div>
                    </div>
                  </div>
                </div>
                <div class="float-right">
                  <Button red onClick={() => deleteCollection(collection)}>
                    Delete Collection
                  </Button>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
