import React, { useEffect, useState } from "react";
import ProfileIcon from "./ProfileIcon";
import getAuthHeaders from "../../utils/Helpers";
import axios from "axios";

export default function DynamicProfileIcon({ userId, ...rest }) {
  console.log("🚀 ~ DynamicProfileIcon ~ rest:", rest);
  console.log("🚀 ~ DynamicProfileIcon ~ userId:", userId);
  const [user, setUser] = useState(null);
  useEffect(() => {
    if (!userId) return;
    const getUser = async () => {
      const config = await getAuthHeaders();

      const result = await axios.post(
        process.env.REACT_APP_SERVER_URL + "/get-user",
        { userId },
        config
      );
      console.log("🚀 ~ useEffect ~ result:", result);
      setUser(result.data);
    };
    getUser();
  }, [userId]);

  return <>{user && <ProfileIcon user={user} {...rest} />}</>;
}
