import React, { useState } from "react";
import Button from "./Button";

export default function TabPages({
  children,
  titles,
  submitButton,
  defaultPage = 0,
}) {
  const [selected, setSelected] = useState(defaultPage);

  const updateSelected = (value) => {
    if (value >= titles.length) {
      return setSelected(titles.length - 1);
    }
    if (value <= 0) {
      return setSelected(0);
    }
    return setSelected(value);
  };

  return (
    <div>
      <div>
        {titles.map((title, i) => (
          <button
            onClick={() => updateSelected(i)}
            class={` ${
              i === selected
                ? "bg-blue-light text-white"
                : "text-grey bg-grey-light hover:bg-blue-dark"
            }  p-3 w-96 text-md font-bold ${i === 0 && "rounded-tl-lg"} ${
              i === titles.length - 1 && "rounded-tr-lg"
            }`}
          >
            {title}
          </button>
        ))}
      </div>
      {children[selected]}
      <div class="flow-root mt-8">
        {selected !== 0 && (
          <div class="float-left w-40">
            <Button secondary onClick={() => updateSelected(selected - 1)}>
              BACK
            </Button>
          </div>
        )}
        {selected !== titles.length - 1 && (
          <div class="float-right w-40">
            <Button secondary onClick={() => updateSelected(selected + 1)}>
              NEXT
            </Button>
          </div>
        )}
        {submitButton && selected === titles.length - 1 && (
          <div class="float-right w-52">{submitButton}</div>
        )}
      </div>
    </div>
  );
}
