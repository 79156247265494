import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PageTitle from "../PageTitle";
import { getFolder } from "../../utils/Requests";
import ProfileIcon from "../accounts/ProfileIcon";
import Button from "../Button";
import FilesTab from "./FilesTab";
import ChecksTab from "./ChecksTab";

export default function Folder() {
  const { folderId } = useParams();
  console.log("🚀 ~ Folder ~ folderId:", folderId);
  const [folder, setFolder] = useState(null);
  const [selectedTab, setSelectedTab] = useState("files");

  useEffect(() => {
    const getFolderData = async () => {
      const response = await getFolder(folderId);
      setFolder(response);
    };
    getFolderData();
  }, []);

  const Info = ({ header, children }) => (
    <div>
      <div>{header}</div>
      <div class="text-grey pt-2 pb-8">{children || "--"}</div>
    </div>
  );

  if (!folder) {
    return <div>Loading...</div>;
  }

  return (
    <div class="grid grid-cols-4 h-full">
      <div class="col-span-3">
        <div class="my-6 mx-3 ">
          <PageTitle
            title={folder ? "My Content > " + folder.title : "My Content"}
          ></PageTitle>
        </div>
        <div>
          <div class="text-grey text-center border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
            <ul class="flex flex-wrap -mb-px">
              <li class="w-1/3">
                <div
                  onClick={() => setSelectedTab("files")}
                  class={`${
                    selectedTab === "files" && "border-b-2 text-black font-bold"
                  } inline-block p-4 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 w-full cursor-pointer`}
                >
                  Files
                </div>
              </li>
              <li class="w-1/3">
                <div
                  onClick={() => setSelectedTab("activity")}
                  class={`${
                    selectedTab === "activity" &&
                    "border-b-2 text-black font-bold"
                  } inline-block p-4 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 w-full cursor-pointer`}
                >
                  Activity
                </div>
              </li>
              <li class="w-1/3">
                <div
                  onClick={() => setSelectedTab("checks")}
                  class={`${
                    selectedTab === "checks" &&
                    "border-b-2 text-black font-bold"
                  } inline-block p-4 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 w-full cursor-pointer`}
                >
                  Checks
                </div>
              </li>
            </ul>
          </div>
          {selectedTab === "files" && (
            <FilesTab folderId={folderId} folder={folder}></FilesTab>
          )}
          {selectedTab === "checks" && (
            <ChecksTab folderId={folderId}></ChecksTab>
          )}
        </div>
      </div>
      <div class="bg-blue-pale p-4">
        <Info header="Folder name">{folder.title}</Info>
        <Info header="Description">{folder.description}</Info>
        <Info header="Tags">{folder.tags}</Info>
        <Info header="Modified">{folder.lastModified}</Info>
        <div class="grid grid-cols-2">
          <Info header="Total Files">{folder.files}</Info>
          <Info header="Folder Size">{folder.size}</Info>
        </div>
        <hr class="text-grey"></hr>
        <Info header="Who can access">
          <ProfileIcon />
        </Info>
        <hr class="text-grey"></hr>
        <div class="grid grid-cols-3 my-6">
          <div>
            <img src="/internal-check.png"></img>
          </div>
          <div class="ml-4 text-sm pt-3 col-span-2 text-blue-dark">
            {`Do you want to perform an originality check with “${
              folder.title || "Untitled"
            }”?`}
          </div>
        </div>
        <Button
          onClick={() => {
            window.location.href = "/check/" + folderId;
          }}
        >
          Check
        </Button>
      </div>
    </div>
  );
}
