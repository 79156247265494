import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

//copied from tailwind.config.js
const colors = {
  yellow: "#FCB124",
  red: "#F30000",
  green: "#008000",
  "blue-light": "#0075CD",
};

export default function Progress({ progress }) {
  return (
    <div class="flex justify-center items-center">
      <div class="w-8">
        <CircularProgressbar
          value={progress * 100}
          strokeWidth={50}
          styles={buildStyles({
            pathColor: progress === 1 ? colors["blue-light"] : colors["green"],
            strokeLinecap: "butt",
          })}
        />
      </div>
      <div class="font-bold text-grey ml-2">{Math.round(progress * 100)}%</div>
    </div>
  );
}
