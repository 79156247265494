import { Link } from "react-router-dom";
import { useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faKey,
  faUser,
  faEyeSlash,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import Button from "../Button";

export default function Login({ isRegister }) {
  const navigate = useNavigate();
  const { currentUser, login, register, setError } = useAuth();
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");

  useEffect(() => {
    if (currentUser) {
      navigate("/");
    }
  }, [currentUser, navigate]);

  async function handleFormSubmit(e) {
    e.preventDefault();

    if (isRegister) {
      if (password !== confirmPassword) {
        return setError("Passwords do not match");
      }
    }

    try {
      setError("");
      setLoading(true);
      if (isRegister) {
        await register(email, password);
      } else {
        await login(email, password);
      }
      navigate("/");
    } catch (e) {
      console.log(e);
      if (isRegister) {
        setError("Failed to register");
      } else {
        setError("Failed to login");
      }
    }

    setLoading(false);
  }

  return (
    <div
      onMouseUp={() => {
        setShowPassword(false);
        setShowConfirmPassword(false);
      }}
    >
      <div
        class="bg-cover bg-center h-[calc(100vh-64px-60px)] content-center"
        style={{ backgroundImage: "url(background.jpeg)" }}
      >
        <div class="grid grid-cols-2">
          <div class="bg-white w-96 ml-20 rounded-2xl absolute bottom-[20%] pb-4">
            <div class="bg-blue-highlight h-14 rounded-t-2xl flex items-center justify-center text-white text-lg">
              PRE-ALPHA V2
            </div>
            <div class="flex items-center justify-center">
              <div class="p-4 mx-8 w-full">
                <img class="mx-auto w-28 m-12" src="/RM.png"></img>
                <form onSubmit={handleFormSubmit}>
                  <div class="mb-12">
                    <div class="border-b my-2 p-2 w-full text-grey">
                      <FontAwesomeIcon icon={faUser} />
                      <input
                        id="email-address"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        placeholder="Email"
                        onChange={(e) => setEmail(e.target.value)}
                        class="pl-2 w-[85%]"
                      />
                    </div>
                    <div class="border-b my-2 p-2 w-full text-grey">
                      <FontAwesomeIcon icon={faKey} />
                      <input
                        id="password"
                        name="password"
                        type={showPassword ? "text" : "password"}
                        autoComplete="current-password"
                        required
                        placeholder="Password"
                        onChange={(e) => setPassword(e.target.value)}
                        class="pl-2 w-[85%]"
                      />
                      <span
                        class="cursor-pointer"
                        onMouseDown={() => setShowPassword(true)}
                      >
                        <FontAwesomeIcon
                          icon={showPassword ? faEye : faEyeSlash}
                        />
                      </span>
                    </div>
                    {isRegister && (
                      <div class="border-b my-2 p-2 w-full text-grey">
                        <FontAwesomeIcon icon={faKey} />
                        <input
                          id="confirmPassword"
                          name="confirmPassword"
                          type={showConfirmPassword ? "text" : "password"}
                          autoComplete="current-password"
                          required
                          placeholder="Confirm Password"
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          class="pl-2 w-[85%]"
                        />
                        <span
                          class="cursor-pointer"
                          onMouseDown={() => setShowConfirmPassword(true)}
                        >
                          <FontAwesomeIcon
                            icon={showConfirmPassword ? faEye : faEyeSlash}
                          />
                        </span>
                      </div>
                    )}
                  </div>
                  <div>
                    {isRegister ? (
                      <Button
                        type="submit"
                        disabled={
                          email.length === 0 ||
                          password.length === 0 ||
                          confirmPassword.length === 0 ||
                          password !== confirmPassword
                        }
                      >
                        {loading ? "REGISTERING..." : "REGISTER"}
                      </Button>
                    ) : (
                      <Button
                        type="submit"
                        disabled={email.length === 0 || password.length === 0}
                      >
                        {loading ? "LOGGING IN..." : "LOG IN"}
                      </Button>
                    )}
                  </div>
                  {isRegister ? (
                    <div class="text-grey text-center pt-4">
                      <Link to="/login">Already have an account? Log in</Link>
                    </div>
                  ) : (
                    <div class="text-grey text-center pt-4">
                      <Link to="/register">
                        Don't have an account? Register
                      </Link>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
          <div class="bg-white-transparent pr-28 right-0 absolute bottom-[20%] rounded-l-2xl p-8">
            <div class="text-blue-light font-bold text-3xl">
              <p>
                Welcome to
                <img class="w-32 inline ml-2" src="/Echo black.png"></img>
              </p>
            </div>
            <div class="text-lg pt-2">Originality First.</div>
          </div>
        </div>
      </div>
      <footer class="sticky z-50 bg-white-transparent bottom-0 p-4 text-sm font-bold ml-3">
        <span class="align-middle text-xl">© </span>
        <span class="align-middle">RM Education Ltd, All Rights Reserved</span>
      </footer>
    </div>
  );
}
