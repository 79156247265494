import React from "react";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import PageTitle from "../PageTitle";
import Button from "../Button";

export default function Settings() {
  return (
    <div class="my-6 mx-3">
      <PageTitle title="Settings" icon={faGear}></PageTitle>
      <div class="mt-20 flex">
        Upload file limit <input type="text" value="18000"></input>
        <div class="w-40">
          <Button>SAVE CHANGES</Button>
        </div>
      </div>
    </div>
  );
}
