import React from "react";
import {
  faEllipsis,
  faPlay,
  faCheck,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const BuildIcon = (type) => {
  if (type === "Preparing") {
    return (
      <div class="rounded-full bg-yellow">
        <div class="flex h-6 w-6 items-center justify-center text-white text-xs">
          <FontAwesomeIcon icon={faEllipsis}></FontAwesomeIcon>
        </div>
      </div>
    );
  }
  if (type === "Running") {
    return (
      <div class="rounded-full bg-green">
        <div class="flex h-6 w-6 items-center justify-center text-white text-xs">
          <FontAwesomeIcon icon={faPlay}></FontAwesomeIcon>
        </div>
      </div>
    );
  }
  if (type === "Error") {
    return (
      <div class="rounded-full bg-red">
        <div class="flex h-6 w-6 items-center justify-center text-white text-xs">
          <FontAwesomeIcon icon={faXmark}></FontAwesomeIcon>
        </div>
      </div>
    );
  }
  return (
    <div class="rounded-full bg-blue-light">
      <div class="flex h-6 w-6 items-center justify-center text-white text-xs">
        <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
      </div>
    </div>
  );
};

export default function Status({ includeHeader, status }) {
  return (
    <div class="flex">
      {includeHeader && (
        <div class="text-grey font-bold text-md mr-2">Status</div>
      )}
      {BuildIcon(status)}
      <div class="font-bold text-md ml-2">{status}</div>
    </div>
  );
}
