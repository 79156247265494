import axios from "axios";
import getAuthHeaders from "./Helpers";

const getCollections = async () => {
  const config = await getAuthHeaders();
  const result = await axios.get(
    process.env.REACT_APP_SERVER_URL + "/get-collections",
    config
  );
  if (result.data.map) {
    const mappedData = result.data.map((item) => ({
      ...item,
      link: "/collections/" + item._id,
    }));
    return mappedData;
  }
};

const getCollection = async (collectionId) => {
  const config = await getAuthHeaders();
  const result = await axios.post(
    process.env.REACT_APP_SERVER_URL + "/get-collection",
    { collectionId },
    config
  );
  return result.data;
};

// const getItem = async (itemId) => {
//   const config = await getAuthHeaders();
//   const result = await axios.post(
//     process.env.REACT_APP_SERVER_URL + "/get-item",
//     { itemId },
//     config
//   );
//   return result.data;
// };

const getCheck = async (checkId) => {
  console.log("GET CHECK");
  const config = await getAuthHeaders();
  const result = await axios.get(
    process.env.REACT_APP_SERVER_URL + "/get-check",
    { ...config, params: { id: checkId } }
  );
  console.log("🚀 ~ getCheck ~ result:", result);
  return result.data;
};

const addExclusions = async (checkId, exclusions) => {
  const config = await getAuthHeaders();
  const result = await axios.post(
    process.env.REACT_APP_SERVER_URL + "/add-exclusions",
    { checkId, exclusions },
    config
  );
  return result.data;
};

const addSensitivity = async (checkId, sensitivity) => {
  const config = await getAuthHeaders();
  const result = await axios.post(
    process.env.REACT_APP_SERVER_URL + "/set-sensitivity",
    { checkId, sensitivity },
    config
  );
  return result.data;
};

const getCheckProgress = async (checkId) => {
  const config = await getAuthHeaders();
  const result = await axios.get(
    process.env.REACT_APP_SERVER_URL + "/get-progress",
    { ...config, params: { id: checkId } }
  );
  return result.data;
};

const getNotifications = async () => {
  return [];
  const config = await getAuthHeaders();
  const result = await axios.get(
    process.env.REACT_APP_SERVER_URL + "/get-notifications",
    config
  );
  return result.data;
};

const seeNotifications = async (notificationIds) => {
  const config = await getAuthHeaders();
  const result = await axios.post(
    process.env.REACT_APP_SERVER_URL + "/see-notifications",
    { notificationIds },
    config
  );
  return result.data;
};

const dismissNotification = async (notificationId) => {
  const config = await getAuthHeaders();
  const result = await axios.post(
    process.env.REACT_APP_SERVER_URL + "/dismiss-notification",
    { notificationId },
    config
  );
  return result.data;
};

const inviteCollaborator = async (collaboratorId, collectionId) => {
  const config = await getAuthHeaders();
  const result = await axios.post(
    process.env.REACT_APP_SERVER_URL + "/invite-collaborator",
    { collaboratorId, collectionId },
    config
  );
  return result.data;
};

const acceptInvite = async (collectionId) => {
  const config = await getAuthHeaders();
  const result = await axios.post(
    process.env.REACT_APP_SERVER_URL + "/accept-invite",
    { collectionId },
    config
  );
  return result.data;
};

// --------------------------------

const createFolder = async (title, description, tags) => {
  const config = await getAuthHeaders();
  const result = await axios.post(
    process.env.REACT_APP_SERVER_URL + "/create-folder",
    { title, description, tags },
    config
  );
  return result.data;
};

const getFolders = async () => {
  const config = await getAuthHeaders();
  const result = await axios.get(
    process.env.REACT_APP_SERVER_URL + "/get-folders",
    config
  );
  return result.data;
};

const getFolder = async (folderId) => {
  const config = await getAuthHeaders();
  const result = await axios.get(
    process.env.REACT_APP_SERVER_URL + "/get-folder",
    { ...config, params: { id: folderId } }
  );
  console.log("🚀 ~ getFolder ~ result:", result);
  return result.data;
};

const populateFolder = async (folderId, files) => {
  const config = await getAuthHeaders("multipart/form-data");

  const data = new FormData();
  data.append("folderId", folderId);
  files.forEach((file) => {
    data.append(file.name, file);
  });

  const result = await axios.post(
    process.env.REACT_APP_SERVER_URL + "/upload-files",
    data,
    config
  );
  return result.data;
};

const startCheck = async (name, folder, references, settings) => {
  const config = await getAuthHeaders();
  const result = await axios.post(
    process.env.REACT_APP_SERVER_URL + "/start-check",
    { name, folder, references, settings },
    config
  );
  return result.data;
};

const getItem = async (itemId) => {
  const config = await getAuthHeaders();
  const result = await axios.get(
    process.env.REACT_APP_SERVER_URL + "/get-item",
    { ...config, params: { id: itemId } }
  );
  return result.data;
};

const getChecks = async (folder) => {
  const config = await getAuthHeaders();
  const result = await axios.get(
    process.env.REACT_APP_SERVER_URL + "/get-checks",
    { ...config, params: { folder } }
  );
  return result.data;
};

export {
  getCollections,
  getCollection,
  getCheck,
  getCheckProgress,
  getItem,
  getNotifications,
  seeNotifications,
  dismissNotification,
  inviteCollaborator,
  acceptInvite,
  addExclusions,
  addSensitivity,
  createFolder,
  getFolders,
  getFolder,
  populateFolder,
  startCheck,
  getChecks,
};
