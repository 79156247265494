import auth from "../config/firebase";

export default async function getAuthHeaders(contentType = "application/json") {
  const user = auth.currentUser;
  const token = user && (await user.getIdToken());
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      "content-type": contentType,
    },
  };
}
