import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Joyride from "react-joyride";

import { AuthProvider } from "./contexts/AuthContext";
import Register from "./components/accounts/Register";
import Login from "./components/accounts/Login";
import Profile from "./components/accounts/Profile";
import WithPrivateRoute from "./utils/WithPrivateRoute";
import ErrorMessage from "./components/layouts/ErrorMessage";
import Header from "./components/layouts/Header";
import SidebarWrapper from "./components/layouts/SidebarWrapper";
import Home from "./components/pages/Home";
import Collections from "./components/pages/collections/Collections";
import Settings from "./components/pages/Settings";
import CollectionCreator from "./components/pages/collections/creator/CollectionCreator";
import CollectionDetails from "./components/pages/collections/CollectionDetails";
import CheckCreator from "./components/pages/checks/creator/CheckCreator";
import CheckDetails from "./components/pages/checks/CheckDetails";
import ItemResults from "./components/pages/ItemResults";
import WithAdminRoute from "./utils/WithAdminRoute";
import Admin from "./components/pages/Admin";
import Trash from "./components/pages/Trash";
import Marketplace from "./components/pages/Marketplace";
import Folder from "./components/pages/Folder";
import Check from "./components/Check";

function App() {
  return (
    <AuthProvider>
      <div id="app">
        <Joyride
          continuous
          steps={[
            {
              target: ".joyride-welcome",
              content:
                "Welcome to RM Echo. Your similarity journey starts here!",
              disableBeacon: true,
            },
            {
              target: ".joyride-create-collection",
              content: "Let's start by creating a new collection!",
            },
            {
              target: ".joyride-collection-name",
              content: "First we can give our collection a name!",
            },
            {
              target: ".joyride-collection-tags",
              content:
                "Then add some tags so we can easily find our collection later!",
            },
            {
              target: ".joyride-collection-description",
              content: "Finally, let's give our new collection a description!",
            },
          ]}
        />
        <Router>
          <Header />
          <ErrorMessage />
          <Routes>
            <Route exact path="/register" element={<Login isRegister />} />
            <Route exact path="/login" element={<Login />} />
            <Route
              exact
              path="/profile"
              element={
                <WithPrivateRoute>
                  <Profile />
                </WithPrivateRoute>
              }
            />
            <Route
              exact
              path="/"
              element={
                <WithPrivateRoute>
                  <SidebarWrapper>
                    <Home></Home>
                  </SidebarWrapper>
                </WithPrivateRoute>
              }
            />
            <Route
              exact
              path="/folder/:folderId"
              element={
                <WithPrivateRoute>
                  <SidebarWrapper>
                    <Folder></Folder>
                  </SidebarWrapper>
                </WithPrivateRoute>
              }
            />
            <Route
              exact
              path="/check/:folderId"
              element={
                <WithPrivateRoute>
                  <SidebarWrapper>
                    <Check></Check>
                  </SidebarWrapper>
                </WithPrivateRoute>
              }
            />
            <Route
              exact
              path="/trash"
              element={
                <WithPrivateRoute>
                  <SidebarWrapper>
                    <Trash></Trash>
                  </SidebarWrapper>
                </WithPrivateRoute>
              }
            />
            <Route
              exact
              path="/marketplace"
              element={
                <WithPrivateRoute>
                  <SidebarWrapper>
                    <Marketplace></Marketplace>
                  </SidebarWrapper>
                </WithPrivateRoute>
              }
            />
            <Route
              exact
              path="/admin"
              element={
                <WithPrivateRoute>
                  <WithAdminRoute>
                    <Admin></Admin>
                  </WithAdminRoute>
                </WithPrivateRoute>
              }
            />
            <Route
              exact
              path="/checks/new"
              element={
                <WithPrivateRoute>
                  <CheckCreator></CheckCreator>
                </WithPrivateRoute>
              }
            />
            <Route
              exact
              path="/checks/:checkId/:primaryItemId"
              element={
                <WithPrivateRoute>
                  <ItemResults></ItemResults>
                </WithPrivateRoute>
              }
            />
            <Route
              exact
              path="/checks/:checkId"
              element={
                <WithPrivateRoute>
                  <CheckDetails></CheckDetails>
                </WithPrivateRoute>
              }
            />
            <Route
              exact
              path="/collections"
              element={
                <WithPrivateRoute>
                  <SidebarWrapper>
                    <Collections></Collections>
                  </SidebarWrapper>
                </WithPrivateRoute>
              }
            />
            <Route
              exact
              path="/collections/new"
              element={
                <WithPrivateRoute>
                  <CollectionCreator from={"/collections"}></CollectionCreator>
                </WithPrivateRoute>
              }
            />
            <Route
              exact
              path="/collections/:collectionId"
              element={
                <WithPrivateRoute>
                  <CollectionDetails></CollectionDetails>
                </WithPrivateRoute>
              }
            />
            <Route
              exact
              path="/new"
              element={
                <WithPrivateRoute>
                  <CollectionCreator from={"/"}></CollectionCreator>
                </WithPrivateRoute>
              }
            />
            <Route
              exact
              path="/settings"
              element={
                <WithPrivateRoute>
                  <SidebarWrapper>
                    <Settings></Settings>
                  </SidebarWrapper>
                </WithPrivateRoute>
              }
            />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Router>
      </div>
    </AuthProvider>
  );
}

export default App;
