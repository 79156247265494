import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function PageTitle({ title, icon }) {
  return (
    <div class="text-blue-dark text-2xl font-bold">
      <span class="mr-2">
        <FontAwesomeIcon icon={icon} />
      </span>
      {title}
    </div>
  );
}
