import { faTrash, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export default function Trash() {
  return (
    <div class="w-full h-full flex items-center justify-center text-center text-grey text-3xl">
      <div>
        <FontAwesomeIcon icon={faTrashAlt} size="3x"></FontAwesomeIcon>
        <br></br>
        <br></br>
        No files in the Trash
      </div>
    </div>
  );
}
