import React, { useEffect, useState } from "react";
import {
  faFolder,
  faFileEdit,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import PageTitle from "../../PageTitle";
import Button from "../../Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import axios from "axios";
import auth from "../../../config/firebase";
import ProfileIcon from "../../accounts/ProfileIcon";
import Table from "../../Table";
import getAuthHeaders from "../../../utils/Helpers";

export default function Collections() {
  const [collections, setCollections] = useState(null);
  const [filteredCollections, setFilteredCollections] = useState(null);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getCollections = async () => {
      const config = await getAuthHeaders();
      setLoading(true);
      const result = await axios.get(
        process.env.REACT_APP_SERVER_URL + "/get-collections",
        config
      );
      setLoading(false);
      console.log("🚀 ~ useEffect ~ result:", result);
      if (result.data.map) {
        console.log("🚀 ~ getCollections ~ result.data:", result.data);
        const mappedData = result.data.map((item) => ({
          ...item,
          link: "/collections/" + item._id,
        }));
        setCollections(mappedData);
        setFilteredCollections(mappedData);
      }
    };
    getCollections();
  }, []);

  useEffect(() => {
    if (!collections) {
      return;
    }
    setFilteredCollections(
      collections.filter((collection) => {
        const splitSearch = search.toLowerCase().split(" ");
        for (const searchWord of splitSearch) {
          if (searchWord.length === 0 || searchWord === " ") {
            continue;
          }
          console.log("searchWord", searchWord);
          if (collection.name.toLowerCase().includes(searchWord)) {
            return true;
          }
          for (const tag of collection.tags) {
            if (tag.toLowerCase().includes(searchWord)) {
              return true;
            }
          }
          // for (const owner of collection.owners) {
          //   console.log("owner", owner);
          //   if (owner.name.includes(searchWord)) {
          //     return true;
          //   }
          // }
        }
        return false;
      })
    );
  }, [search]);

  const renderDataFunction = (key, value) => {
    if (key === "creator") {
      return <ProfileIcon user={value} color="black"></ProfileIcon>;
    }
    if (key === "sharedWith") {
      return (
        <div class="flex">
          {value.map((user) => (
            <div class="ml-4">
              <ProfileIcon user={user} color="black"></ProfileIcon>
            </div>
          ))}
        </div>
      );
    }
    if (key === "lastUpdated") {
      return new Date(value).toLocaleString();
    }
    if (key === "tags") {
      return value.join(", ");
    }
    return value;
  };

  return (
    <div class="my-6 mx-3">
      <PageTitle title="Collections" icon={faFolder}></PageTitle>
      <div class="flow-root">
        <div class="float-left my-4">
          Search
          <input
            class="w-96 ml-4 pl-4 h-10 border-grey border rounded-full"
            placeholder="🔍 Search by name, tag or owner"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          ></input>
        </div>
        <div class="w-80 float-right">
          <Link to="/collections/new">
            <Button>
              <span class="mr-2">
                <FontAwesomeIcon icon={faFileEdit}></FontAwesomeIcon>
              </span>
              CREATE COLLECTION
            </Button>
          </Link>
        </div>
      </div>
      {loading && (
        <div class="w-full h-full text-center text-blue-dark text-xl font-bold mt-40">
          Fetching your collections...
          <br></br>
          <br></br>
          <FontAwesomeIcon icon={faSpinner} size="5x" spin={true} />
        </div>
      )}
      {collections && collections.length > 0 && (
        <Table
          data={search.length > 0 ? filteredCollections : collections}
          headers={Object.keys(collections[0])}
          renderData={renderDataFunction}
          filter={["_id", "description", "owners", "link", "history", "items"]}
          linkKey="link"
        ></Table>
      )}
      {collections && collections.length === 0 && (
        <div class="w-full h-full text-center text-blue-dark text-xl font-bold mt-40">
          <p>
            No collections found. <br></br>Create one by clicking the button
            above.
          </p>
        </div>
      )}
    </div>
  );
}
