import React, { useEffect, useState } from "react";
import Button from "../../../Button";
import { Link } from "react-router-dom";
import Status from "../../../Status";
import CollectionCreatorDetails from "./CollectionCreatorDetails";
import Loading from "../../../Loading";

export default function CollectionCreator({ from }) {
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (done) {
      window.location.href = "/collections";
    }
  }, [done]);

  return (
    <div class="my-6 mx-3">
      <div class="grid grid-cols-3 mb-12">
        <div class="w-60">
          <Link to={from || "/"}>
            <Button secondary>{`BACK TO ${
              from === "/collections" ? "COLLECTIONS" : "HOME"
            }`}</Button>
          </Link>
        </div>
        <div class="text-center text-blue-light font-bold text-xl">
          CREATING A NEW COLLECTION
        </div>
        <div class="flow-root">
          <div class="float-right">
            <Status includeHeader status="Preparing" />
          </div>
        </div>
      </div>
      {!loading && !done && (
        <CollectionCreatorDetails
          setLoading={setLoading}
          setDone={setDone}
          setError={setError}
        />
      )}
      {loading && <Loading />}
    </div>
  );
}
