import React, { useEffect, useState } from "react";
import auth from "../../../config/firebase";
import axios from "axios";
import Button from "../../Button";
import TabPages from "../../TabPages";
import Table from "../../Table";
import { Link, useParams } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";
import ProfileIcon from "../../accounts/ProfileIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers, faXmark } from "@fortawesome/free-solid-svg-icons";
import getAuthHeaders from "../../../utils/Helpers";
import Modal from "../../Modal";
import DynamicProfileIcon from "../../accounts/DynamicProfileIcon";
import {
  getFolder,
  getFolders,
  inviteCollaborator,
} from "../../../utils/Requests";

export default function CollectionDetails() {
  const { currentUser } = useAuth();
  const { collectionId } = useParams();
  const [loading, setLoading] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [collection, setCollection] = useState(null);
  const [tags, setTags] = useState(new Set());
  const [tagInput, setTagInput] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [owners, setOwners] = useState([]);
  console.log("🚀 ~ CollectionDetails ~ owners:", owners);
  const [collaboratorModal, setCollaboratorModal] = useState(false);
  const [collaboratorSearch, setCollaboratorSearch] = useState("");
  const [collaborators, setCollaborators] = useState(new Set());
  console.log("🚀 ~ CollectionDetails ~ collaborators:", collaborators);
  const [allUsers, setAllUsers] = useState([]);
  console.log("🚀 ~ CollectionDetails ~ collaboratorModal:", collaboratorModal);

  console.log("🚀 ~ CollectionDetails ~ collection:", collection);

  useEffect(() => {
    const getCollection = async () => {
      setLoading(true);
      const result = await getFolder(collectionId);
      setLoading(false);
      const resultCollection = result.data;
      console.log("🚀 ~ getCollection ~ resultCollection:", resultCollection);
      setCollection(resultCollection);
      setName(resultCollection.name);
      setTags(new Set(resultCollection.tags));
      setDescription(resultCollection.description);
      setOwners(resultCollection.owners);
    };
    const getAllUsers = async () => {
      const config = await getAuthHeaders();
      const result = await axios.get(
        process.env.REACT_APP_SERVER_URL + "/get-users",
        config
      );
      setAllUsers(
        result.data.filter((user) => user.uid !== auth.currentUser.uid)
      );
      console.log("🚀 ~ getAllUsers ~ result.data:", result.data);
    };
    getCollection();
    getAllUsers();
  }, []);

  const getHistoryText = (item) => {
    if (item.event === "created") {
      return 'Collection created as "' + item.name + '"';
    }
    if (item.event === "check") {
      return 'Check "' + item.checkName + '" started';
    }
    return "Collection " + item.field + ' updated to "' + item.value + '"';
  };

  const updateCollection = async () => {
    const user = auth.currentUser;
    const token = user && (await user.getIdToken());
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        // "content-type": "multipart/form-data",
      },
    };

    setLoadingUpdate(true);

    console.log("UPDATE OWNERS", owners);

    const response = await axios.post(
      process.env.REACT_APP_SERVER_URL + "/update-collection",
      {
        collectionId,
        newCollection: {
          name,
          description,
          tags: [...tags],
          owners: owners,
        },
      },
      config
    );
    if (response.status === 200) {
      window.location.reload();
      setLoadingUpdate(false);
      //   setDone(true);
    } else {
      setLoadingUpdate(false);
      //   setError(true);
    }
  };

  const historyRenderer = (key, value) => {
    if (key === "performedBy") {
      return (
        <DynamicProfileIcon userId={value} color="black"></DynamicProfileIcon>
      );
    }
    return value;
  };

  const deleteCollection = async () => {
    const response = window.confirm(
      `Are you sure you want to delete ${
        collection?.name || "this collection"
      }?`
    );
    if (!response) return;
    const user = auth.currentUser;
    const token = user && (await user.getIdToken());
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "multipart/form-data",
      },
    };
    const result = await axios.post(
      process.env.REACT_APP_SERVER_URL + "/delete-collection",
      { collectionId: collectionId },
      config
    );
    if (result.status === 200) {
      window.location.href = "/collections";
    }
  };

  const updateTags = (input) => {
    if (input.includes("\n")) {
      const before = input.split("\n")[0];
      const after = input.split("\n")[1];
      if (before.length > 0) {
        setTags(tags.add(before));
      }
      setTagInput(after);
    } else {
      setTagInput(input);
    }
  };

  return (
    <>
      <Modal isOpen={collaboratorModal} setIsOpen={setCollaboratorModal}>
        <div class="text-xl">
          <FontAwesomeIcon icon={faUsers}></FontAwesomeIcon> Add Collaborator
        </div>
        {allUsers
          .filter(
            (user) =>
              user?.displayName
                ?.toLowerCase()
                .includes(collaboratorSearch.toLowerCase()) ||
              user.email
                .toLowerCase()
                .includes(collaboratorSearch.toLowerCase())
          )
          .map((user) => (
            <div
              class={`cursor-pointer ${
                collaborators.has(user.uid) ? "bg-blue-light text-white" : ""
              }`}
              onClick={() =>
                collaborators.has(user.uid)
                  ? setCollaborators(
                      new Set(
                        [...collaborators].filter((id) => id !== user.uid)
                      )
                    )
                  : setCollaborators(new Set(collaborators.add(user.uid)))
              }
            >
              <ProfileIcon user={user}></ProfileIcon>
            </div>
          ))}
        <input
          class="w-96 ml-4 pl-4 h-10 border-grey border rounded-full text-blue"
          placeholder="🔍 Search by display name or email"
          onChange={(e) => setCollaboratorSearch(e.target.value)}
          value={collaboratorSearch}
        ></input>
        <div class="w-40 flex justify-center items-center">
          <Button
            onClick={() => {
              setOwners([...new Set([...owners, ...collaborators])]);
              setCollaboratorModal(false);
              [...collaborators].forEach((uid) => {
                inviteCollaborator(uid, collectionId);
              });
              //   updateCollection();
            }}
          >
            Send Invite
          </Button>
        </div>
      </Modal>
      <div class="my-6 mx-3">
        <div class="grid grid-cols-3 mb-6">
          <div class="w-60">
            <Link to={"/collections"}>
              <Button secondary>BACK TO COLLECTIONS</Button>
            </Link>
          </div>
          <div class="text-center text-blue-light font-bold text-2xl">
            {collection?.name || "Collection"}
          </div>
          <div class="flow-root">
            <div class="float-right w-60">
              <Button red onClick={() => deleteCollection()}>
                DELETE {collection?.name.toUpperCase() || "COLLECTION"}
              </Button>
            </div>
          </div>
        </div>
        {collection && (
          <div>
            <TabPages
              titles={["INFORMATION", "ITEMS", "HISTORY"]}
              defaultPage={1}
            >
              <div class="h-full pb-24 bg-white-dark border-grey border-t-2 grid grid-cols-2 grid-rows-2">
                <div class="flex items-center justify-center">
                  <div>
                    <div class="text-lg font-bold m-4">
                      Collection name:<span class="m-2 text-red">*</span>
                    </div>
                    <input
                      class="border-2 border-grey rounded-lg w-96 h-16 p-2"
                      placeholder="Enter the name of the collection"
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                      // value={collection.name}
                    ></input>
                  </div>
                </div>
                <div class="flex items-center justify-center">
                  <div class="w-1/2">
                    <div class="text-lg font-bold m-4">Add tags:</div>
                    <div class="mb-4">
                      {[...tags].map((tag) => (
                        <span class="ml-2 bg-blue-dark rounded-full text-white p-2">
                          {tag}
                          <span
                            class="ml-2 cursor-pointer"
                            onClick={() => {
                              const newTags = new Set([...tags]);
                              newTags.delete(tag);
                              setTags(newTags);
                            }}
                          >
                            <FontAwesomeIcon icon={faXmark}></FontAwesomeIcon>
                          </span>
                        </span>
                      ))}
                    </div>
                    <textarea
                      class="border-2 border-grey rounded-lg w-96 h-16 p-2"
                      placeholder="Add a tag"
                      onChange={(e) => updateTags(e.target.value)}
                      value={tagInput}
                    ></textarea>
                    Type a tag then press 'Enter' to add.
                  </div>
                </div>
                <div class="flex items-center justify-center">
                  <div>
                    <div class="text-lg font-bold m-4">Description:</div>
                    <textarea
                      class="border-2 border-grey rounded-lg w-96 h-32 p-2"
                      placeholder="Enter a short description"
                      onChange={(e) => setDescription(e.target.value)}
                      value={description}
                      // value={collection.description}
                    ></textarea>
                  </div>
                </div>
                <div class="flex items-center justify-center">
                  <div>
                    <div class="text-lg font-bold m-4">Owners:</div>
                    <div class="border-2 border-grey rounded-lg w-96 h-16 p-2 flex">
                      {[collection.creator, ...owners].map((owner) => {
                        return (
                          <div class="ml-2">
                            <DynamicProfileIcon
                              userId={owner}
                              color="black"
                              bold
                            ></DynamicProfileIcon>
                          </div>
                        );
                      })}
                    </div>
                    <Button onClick={() => setCollaboratorModal(true)}>
                      Add Collaborator
                    </Button>
                  </div>
                </div>
                <div></div>
                <div class="flow-root">
                  <div class="float-right w-60">
                    <Button onClick={() => updateCollection()}>
                      {loadingUpdate
                        ? "UPDATING COLLECTION..."
                        : "UPDATE COLLECTION"}
                    </Button>
                  </div>
                </div>
              </div>
              <div class="h-full pb-24 bg-white-dark border-grey border-t-2">
                <Table data={collection.files}></Table>
                <div class=" m-4 float-right">
                  Total items:{" "}
                  <span class="font-bold">{collection.files.length}</span>
                </div>
              </div>
              <div class="h-full pb-24 bg-white-dark border-grey border-t-2">
                <Table
                  headers={["DETAILS", "ACTION", "PERFORMED BY", "EVENT LOG"]}
                  data={(collection?.history || []).map((item) => ({
                    details: getHistoryText(item),
                    action: null,
                    performedBy: item.user,
                    eventLog: item.date,
                  }))}
                  renderData={historyRenderer}
                ></Table>
              </div>
            </TabPages>
          </div>
        )}
      </div>
    </>
  );
}
