import React from "react";
import NewFolder from "./layouts/NewFolder";

export default function HomepageBanner() {
  return (
    <div class="w-full h-64 bg-blue-light">
      <div class="grid grid-cols-3">
        <div class="px-16 pt-12 col-span-2">
          <div class="text-white text-4xl">
            <p>
              Want to <b>check</b> the <b>similarity</b> or <b>originality</b>{" "}
              of your content?
            </p>
          </div>
          <div class="w-1">
            <NewFolder banner></NewFolder>
          </div>
        </div>
        <div class="ml-8">
          <img class="mt-8 h-56" src="banner-image.png"></img>
        </div>
      </div>
    </div>
  );
}
