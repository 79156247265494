import React from "react";

export default function ProfileIcon({ user, color, bold }) {
  // console.log("🚀 ~ ProfileIcon ~ user:", user);
  return (
    <div class="flex">
      <div
        class="bg-white rounded-full w-8 h-8 bg-cover bg-center border border-blue-dark"
        style={{
          backgroundImage: `url(${
            user?.photoURL || user?.picture || "/default.png"
          })`,
        }}
      >
        {/* <img class="inline" src={user?.photoURL || "/default.png"}></img> */}
      </div>
      <span
        class={`text-${color || "white"} ${
          bold && "font-bold"
        } text-md ml-2 mt-1`}
      >
        {user?.displayName || user?.name || user?.email || "Profile"}
      </span>
    </div>
  );
}
